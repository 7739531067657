import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { AnyMongoAbility } from '@casl/ability';

import { RootState } from '../store';
import { CurrentUser } from '../../../models/CurrentUserModel';
// import { emptyAbility } from '../../../utils/permission';

type InitialState = {
    currentUser: CurrentUser | undefined;
    // ability: AnyMongoAbility,
};

const initialState: InitialState = {
    currentUser: undefined,
    // ability: emptyAbility,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (
            state: InitialState,
            action: PayloadAction<CurrentUser>
        ) => {
            state.currentUser = action.payload;
        },
        // setAbility: (state: InitialState, action: PayloadAction<AnyMongoAbility>) =>  {
        //     state.ability = action.payload;
        //     state.ability.
        // },
    },
});

export const { setCurrentUser /*, setAbility*/ } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;
// export const selectAbility = (state: RootState) => state.user.ability;

export default userSlice.reducer;
