import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '@mui/utils';

import { CustomSortDirection, getComparator } from './utils';

type Props<T> = {
    headers: {
        label: string;
        link: keyof T;
    }[];
    columns: (T & {
        isActionRequiredBoolean?: boolean;
    })[];
    defaultOrderBy: keyof T;
    uniqTitleIdentifier?: keyof T;
    uniqAppVersionIdentifier?: keyof T;
    appIdentifier?: keyof T;
    selectedTab: 'pending' | 'validated' | 'rejected' | 'scan_failed';
    onRowClick?: (link: string) => void;
};

const TableSimple = <T,>({
    headers,
    columns,
    defaultOrderBy,
    uniqAppVersionIdentifier,
    selectedTab,
    onRowClick,
}: Props<T>) => {
    const [order, setOrder] = useState<CustomSortDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy);

    const filteredActionsLabel: (T & {
        isActionRequiredBoolean?: boolean;
    })[] = [];
    if (selectedTab === 'pending' && orderBy === 'isActionRequired') {
        filteredActionsLabel.push(
            ...columns.filter((c) => c.isActionRequiredBoolean)
        );
        filteredActionsLabel.push(
            ...columns.filter((c) => !c.isActionRequiredBoolean)
        );
        if (order === 'asc') {
            filteredActionsLabel.reverse();
        }
    } else {
        filteredActionsLabel.push(...columns);
    }

    const handleRequestSort = (property: keyof T) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <TableContainer className="table-container" component={Paper}>
            <Box sx={{ overflow: 'auto' }}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow
                            className="table-header"
                            sx={{
                                '& > *': { borderBottom: 'unset' },
                            }}
                        >
                            {headers.map((header) => (
                                <TableCell
                                    key={`${header.label}`}
                                    sortDirection={
                                        orderBy === header.link ? order : false
                                    }
                                    sx={{
                                        width: ['airline', 'version'].includes(
                                            header.link.toString()
                                        )
                                            ? '10%'
                                            : '14.258%',
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            header.label !== 'isRequired' &&
                                            orderBy === header.link
                                        }
                                        direction={
                                            orderBy === header.link
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={() => {
                                            handleRequestSort(header.link);
                                        }}
                                    >
                                        {header.label !== 'isRequired'
                                            ? header.label
                                            : 'Action required'}
                                        {orderBy === header.link ? (
                                            <Box
                                                component="span"
                                                sx={visuallyHidden}
                                            >
                                                {order === 'desc'
                                                    ? 'sorted descending'
                                                    : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredActionsLabel
                            .slice()
                            .sort(getComparator(order, orderBy))
                            .map((column: T, index: number) => {
                                const data = headers.map((header) => {
                                    return (
                                        <TableCell
                                            sx={{
                                                width: [
                                                    'airline',
                                                    'version',
                                                ].includes(
                                                    header.link.toString()
                                                )
                                                    ? '10%'
                                                    : '14.258%',
                                            }}
                                            key={header.label}
                                        >
                                            <span
                                                className={
                                                    header.link === 'title'
                                                        ? 'bold-label'
                                                        : 'unbold-label'
                                                }
                                            >
                                                {column[header.link]}
                                            </span>
                                        </TableCell>
                                    );
                                });
                                return (
                                    <TableRow
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#e0e3e993',
                                            },
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (
                                                onRowClick &&
                                                uniqAppVersionIdentifier
                                            ) {
                                                onRowClick(
                                                    `${column[uniqAppVersionIdentifier]}`
                                                );
                                            }
                                        }}
                                        key={index}
                                    >
                                        {data}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
        </TableContainer>
    );
};

export default TableSimple;
