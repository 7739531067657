import React, { useState } from 'react';
import i18n from 'i18next';
import { ComboBox } from '@airbus/components-react';
import TrashIcon from '../../../icons/TrashIcon';

import classes from './ApplicationChoice.module.css';

type Props = {
    applications: {
        id: number;
        name: string;
        versions: string[];
    }[];
    onSetApp: (appId: number | undefined) => void;
    onSetVersion: (version: string | undefined) => void;
    onDelete: () => void;
};

const ApplicationChoice = ({
    applications,
    onSetApp,
    onSetVersion,
    onDelete,
}: Props) => {
    const [appId, setAppId] = useState<number>();
    const [version, setVersion] = useState<string>();

    const applicationAsOption = applications.map((app) => ({
        label: app.name,
        value: app.id.toString(),
    }));

    const versions =
        applications.find((application) => {
            return application.id === appId;
        })?.versions ?? [];

    return (
        <div className={classes.container}>
            <div className={classes.chose_app}>
                <div className={classes.chose_app_combobox}>
                    <ComboBox
                        placeholder={i18n.t(
                            'deployment.schedule.apps_to_deploy_select_apps'
                        )}
                        options={applicationAsOption}
                        hideClear
                        onChange={(value) => {
                            if (Array.isArray(value)) {
                                return;
                            }

                            if (typeof value === 'string') {
                                return;
                            }

                            const id = Number(value.value);

                            if (id === appId) {
                                return;
                            }

                            setAppId(id);
                            onSetApp(id);
                            setVersion(undefined);
                            onSetVersion(undefined);
                        }}
                    />
                </div>
                <div className={classes.chose_app_trash}>
                    <button
                        className={classes.button_remove}
                        onClick={() => {
                            onDelete();
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>
            <div className={classes.chose_app_version_combobox}>
                <ComboBox
                    placeholder={i18n.t(
                        'deployment.schedule.apps_to_deploy_select_version'
                    )}
                    options={versions}
                    hideClear
                    value={version ?? ''}
                    disabled={appId === undefined}
                    onChange={(value) => {
                        if (Array.isArray(value)) {
                            return;
                        }

                        if (value) {
                            onSetVersion(value);
                            setVersion(value);
                        } else {
                            onSetVersion(undefined);
                            setVersion(undefined);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ApplicationChoice;
