import React from 'react';
import { Flex, Spinner } from '@airbus/components-react';
import classes from './LoginSpinner.module.css';

type Props = {
    putLoginLabel?: boolean;
    fullPage?: boolean;
};

const LoadingSpinner = ({ putLoginLabel = true, fullPage = true }: Props) => {
    const css = fullPage ? classes.full_page_loader : classes.container_loader;
    return (
        <Flex alignItems="center" justify="center" className={css}>
            <Spinner
                size="medium"
                label={putLoginLabel ? 'Logging In' : undefined}
            />
        </Flex>
    );
};

export default LoadingSpinner;
