import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import * as DateUtils from '../../../../utils/DateUtils';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@airbus/components-react';
import {
    Chevrondown,
    Chevronup,
    SubdirectoryArrowRight,
} from '@airbus/icons/react';

import '../../../../i18n/config';
import {
    CurrentAppDisplayData,
    CurrentRowDisplayData,
    CurrentAppVersionData,
} from '../../types';
import { ContentStatusLabel } from '../../../../models/CurrentConfig';
import styles from './DataTable.module.css';
import i18n from 'i18next';
import { PALETTE } from '../../../../constants/CurrentConfig';
type CurrentAppDisplayContent = {
    id: number;
    name: string;
    domain: string;
    providerId: string;
    size: string;
    installed_on: string;
    status: string;
};

type AppVersionDisplayContent = {
    id: number;
    name: string;
    domain: string;
    size: string;
    installedOn: string;
};

type AppRowContent = {
    mainRow: CurrentAppDisplayContent;
    subRows: AppVersionDisplayContent[];
};

type Props = {
    row: CurrentAppDisplayData;
};

const toContent = (app: CurrentRowDisplayData): CurrentAppDisplayContent => ({
    id: app.id,
    name: app.name,
    domain: '',
    providerId: app.providerId,
    size: app.size,
    installed_on: DateUtils.prettyDate(app.installed_on),
    status: app.status,
});

export const CONTENT_STATUS = (status: string) => {
    let style: ContentStatusLabel = {
        LABEL: '',
        COLOR: '',
    };
    if (status === 'deployed') {
        style = {
            LABEL: `${i18n.t('dataStatus.appReady')}`,
            COLOR: PALETTE.CHECK_GREEN,
        };
    } else if (status === 'nonDeployed' || status === 'missingOkube') {
        style = {
            LABEL: `${i18n.t('dataStatus.appError')}`,
            COLOR: PALETTE.RED_ERROR,
        };
    }
    return style;
};

const toSubContent = (
    appVersion: CurrentAppVersionData
): AppVersionDisplayContent => ({
    id: appVersion.id,
    name: appVersion.name,
    domain: appVersion.domain,
    size: appVersion.size,
    installedOn: DateUtils.prettyDate(appVersion.updated_at),
});

const appDataToRow = (app: CurrentAppDisplayData): AppRowContent => ({
    mainRow: toContent(app.mainApp),
    subRows: app.appVersion.map(toSubContent),
});

const DataRow = ({ row }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    row.appVersion.sort(
        (a: CurrentAppVersionData, b: CurrentAppVersionData) => {
            return (
                (b.updated_at ? b.updated_at.getTime() : 0) -
                (a.updated_at ? a.updated_at.getTime() : 0)
            );
        }
    );
    const appContent: AppRowContent = appDataToRow(row);
    return (
        <>
            <tr style={open ? { height: '2em' } : { height: '0.5em' }} />
            <TableRow
                className={`${styles.data_row} ${
                    open ? styles.data_row_open : ''
                }`}
            >
                <TableCell
                    className={
                        open ? styles.data_cell_bold : styles.font_weight_bold
                    }
                >
                    {appContent.mainRow.name}
                </TableCell>
                <TableCell
                    className={
                        open ? styles.data_cell_bold : styles.font_weight_bold
                    }
                >
                    {appContent.mainRow.domain}
                </TableCell>
                <TableCell className={open ? styles.color_white : ''}>
                    {appContent.mainRow.providerId}
                </TableCell>
                <TableCell className={open ? styles.color_white : ''}>
                    {appContent.mainRow.size}
                </TableCell>
                <TableCell className={open ? styles.color_white : ''}>
                    {appContent.mainRow.installed_on}
                </TableCell>
                <TableCell className={open ? styles.color_white : ''}>
                    <Typography
                        className={styles.contentStatus}
                        style={{
                            backgroundColor: CONTENT_STATUS(
                                appContent.mainRow.status
                            ).COLOR,
                        }}
                    >
                        {CONTENT_STATUS(appContent.mainRow.status).LABEL}
                    </Typography>
                </TableCell>

                <TableCell>
                    <IconButton
                        style={open ? { color: 'white' } : { color: '#063b9e' }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Chevronup /> : <Chevrondown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow
                key={appContent.mainRow.id}
                style={open ? { border: '3px solid #00205b' } : {}}
            >
                <TableCell
                    key={appContent.mainRow.id}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table>
                                <TableBody>
                                    {appContent.subRows.length > 0 &&
                                        appContent.subRows.map(
                                            (
                                                appVersion: AppVersionDisplayContent,
                                                index: number
                                            ) => (
                                                <TableRow
                                                    key={index}
                                                    className={styles.table_row}
                                                >
                                                    <TableCell
                                                        className={
                                                            styles.sub_row_app
                                                        }
                                                    >
                                                        <SubdirectoryArrowRight
                                                            className={
                                                                styles.subdirectory_arrow_right
                                                            }
                                                        />
                                                        {appVersion.name || '-'}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            styles.sub_row_sub_type
                                                        }
                                                    >
                                                        <div>
                                                            {appVersion.domain}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            styles.sub_row_provider
                                                        }
                                                    ></TableCell>
                                                    <TableCell
                                                        className={
                                                            styles.sub_row_size
                                                        }
                                                    >
                                                        <div>
                                                            {appVersion.size}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        className={
                                                            styles.sub_row_sub_type
                                                        }
                                                    >
                                                        <div>
                                                            {appVersion.installedOn ||
                                                                '-'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            styles.sub_row_app
                                                        }
                                                    ></TableCell>
                                                </TableRow>
                                            )
                                        )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DataRow;
