import i18n from 'i18next';
import { IHeadCell } from '../components/my-apps/DataTable';
import '../i18n/config';

const RED = '#eb2448';
const BLUE = '#61b7e8';
const GREEN = '#21ce95';

export const VALIDATED = 'validated';
export const PENDING = 'pending';
export const REJECTED = 'rejected';

export const APP_STATUS: any = (state: string) => {
    let style = {
        LABEL: '',
        COLOR: '',
    };
    if (state === PENDING) {
        style = {
            LABEL: `${i18n.t('applications.table.status1')}`,
            COLOR: BLUE,
        };
    }

    if (state === REJECTED) {
        style = {
            LABEL: `${i18n.t('applications.table.status2')}`,
            COLOR: RED,
        };
    }
    if (state === VALIDATED) {
        style = {
            LABEL: `${i18n.t('applications.table.status3')}`,
            COLOR: GREEN,
        };
    }
    return style;
};

export const HEAD_CELLS: IHeadCell[] = [
    {
        id: 'name',
        label: `${i18n.t('applications.table.headerCell1')}`,
    },
    {
        id: 'version',
        label: `${i18n.t('applications.table.headerCell2')}`,
    },
    {
        id: 'createdAt',
        label: `${i18n.t('applications.table.headerCell3')}`,
    },
    {
        id: 'state',
        label: `${i18n.t('applications.table.headerCell4')}`,
    },
];
