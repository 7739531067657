import React from 'react';
import '../home/Home.css';
import i18n from 'i18next';
import '../../i18n/config';
import { Component } from 'react';
import {
    ColorModeProvider,
    Divider,
    Typography,
} from '@airbus/components-react';
import SdkDocumentation from '../sdk-documentation/SdkDocumentation';
import S3Download from '../s3-download/S3Download';

function Menu() {
    return (
        <div>
            <Divider className="m-divider" />
            <SdkDocumentation />
            {/* <FirstAppContainer/> */}
            <S3Download />
            {/* <SharpenYourSkills/> */}
        </div>
    );
}

class GettingStarted extends Component {
    render() {
        console.log('started');
        return (
            <div className="center">
                <ColorModeProvider mode="dark">
                    <div className="bg-primaryblue-90 p-6 flex-container">
                        <div className="text-left flex-item">
                            <Typography variant="h2">
                                {i18n.t('gettingStarted.title')}
                            </Typography>
                            <Typography variant="small">
                                {i18n.t('gettingStarted.content1')}
                                <br />
                                <br />
                                {i18n.t('gettingStarted.content2')}
                            </Typography>
                        </div>
                        <div className="text-left flex-item">
                            {/*TODO: ADD IMAGE */}
                        </div>
                    </div>
                </ColorModeProvider>
                <div className="p-title">
                    <Typography variant="h4">
                        {i18n.t('gettingStarted.subtitle')}
                    </Typography>
                </div>
                <Menu />
            </div>
        );
    }
}

export default GettingStarted;
