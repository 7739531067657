export type CustomSortDirection = 'asc' | 'desc';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    const paramA = a[orderBy];
    const paramB = b[orderBy];

    if (!paramA) {
        return 1;
    }
    if (!paramB) {
        return -1;
    }

    if (paramB < paramA) {
        return -1;
    }
    if (paramB > paramA) {
        return 1;
    }
    return 0;
};

export const getComparator = <T>(
    order: CustomSortDirection,
    orderBy: keyof T
) => {
    return order === 'desc'
        ? (a: T, b: T) => descendingComparator(a, b, orderBy)
        : (a: T, b: T) => -descendingComparator(a, b, orderBy);
};
