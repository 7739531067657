import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@airbus/components-react';
import { useState } from 'react';
import { envStage } from '../../utils/ApplicationUtils';
import { EnvStage } from '../../constants/EnvStage';

type Props = {
    showAdmin: boolean;
    showValidationCenter: boolean;
    showDeployment: boolean;
    showAircraft: boolean;
};

const HeaderTab = ({
    showAdmin,
    showValidationCenter,
    showDeployment,
    showAircraft,
}: Props) => {
    let windowUrl = window.location.pathname;
    const ADMIN = '/admin';
    const SWAGGER = '/swagger';
    const APPLICATIONS = '/applications';
    const VALIDATION_CENTER = '/validation-center';
    const DEPLOYMENT = '/deployment';
    const AIRCRAFT = '/aircraft';
    const GETTING_STARTED = '/getting-started';
    const LOGIN = '/login';
    windowUrl = windowUrl.includes(ADMIN) ? '/admin' : windowUrl;
    const url = windowUrl
        ? windowUrl === '/' || windowUrl === LOGIN
            ? GETTING_STARTED
            : windowUrl
        : '';
    const [selectedTab, setSelectedTab] = useState(url);

    return (
        <Tabs
            aria-label="Tabs purpose label"
            value={selectedTab}
            onChange={(event, value) => setSelectedTab(value)}
        >
            <Tab component={Link} to={GETTING_STARTED} value={GETTING_STARTED}>
                {i18n.t('header.tab1')}
            </Tab>
            <Tab component={Link} to={APPLICATIONS} value={APPLICATIONS}>
                {i18n.t('header.tab2')}
            </Tab>
            {showValidationCenter && (
                <Tab
                    component={Link}
                    to={VALIDATION_CENTER}
                    value={VALIDATION_CENTER}
                >
                    {i18n.t('header.tab_validation_center')}
                </Tab>
            )}
            {showDeployment && (
                <Tab component={Link} to={DEPLOYMENT} value={DEPLOYMENT}>
                    {i18n.t('header.tab_deployment')}
                </Tab>
            )}
            {showAircraft && (
                <Tab component={Link} to={AIRCRAFT} value={AIRCRAFT}>
                    {i18n.t('header.tab_aircraft')}
                </Tab>
            )}
            {envStage === EnvStage.Int && (
                <Tab component={Link} to={SWAGGER} value={SWAGGER}>
                    {i18n.t('header.tab3')}
                </Tab>
            )}
            {showAdmin && (
                <Tab component={Link} to={ADMIN} value={ADMIN}>
                    {i18n.t('header.tab4')}
                </Tab>
            )}
        </Tabs>
    );
};

export default HeaderTab;
