import React, { useState, useContext } from 'react';
import { Select } from '@airbus/components-react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import '../../../i18n/config';

import { RoleContext } from './RoleContext';
interface RoleSelectProps {
    options: any;
}

export const RoleSelect = ({ options }: RoleSelectProps) => {
    const { selectedRoleId, setRole, setEditMode } = useContext(RoleContext);
    const [defaultValue, setDefaultValue] = useState<number>(0);

    useDeepCompareEffectNoCheck(() => {
        if (selectedRoleId === 0 && options?.length > 0) {
            setDefaultValue(options[0].value);
        } else setDefaultValue(selectedRoleId || 0);
    }, [selectedRoleId, options]);

    return (
        <div className="role-select-container">
            <Select
                placeholder="Choose an option"
                className="role-select-component"
                onChange={(event: any) => {
                    setRole(event.target.value);
                    setEditMode(false);
                }}
                value={defaultValue}
            >
                {options?.map((option: { value: string; label: string }) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </div>
    );
};
