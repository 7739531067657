import {
    Card,
    CardContent,
    Inline,
    Tooltip,
    Typography,
    Divider,
} from '@airbus/components-react';

import i18n from 'i18next';
import React, { useContext, useEffect } from 'react';
import styles from '../AircraftConfiguration.module.css';
import PlaneIcon from '../../../../images/AirbusA320.png';
import LoadingSpinner from '../../../loaders/LoginSpinner';
import { LightAircraft } from '../../types';
import { getCurrentConfig } from '../../../../services/api/CurrentConfig';
import { useSelector } from 'react-redux';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../../../redux/app/features/credentialsSlice';
import { AircraftContext } from '../../AircraftContext';
import CurrentConfigTable from './CurrentConfigTable';

type AircraftOverviewProps = {
    isFirstLoading: boolean;
    aircraftData: LightAircraft | undefined;
    aircraftIsLoading: boolean;
};

const AircraftOverview = ({
    isFirstLoading,
    aircraftData,
    aircraftIsLoading,
}: AircraftOverviewProps) => {
    const { selectedAircraftId } = useContext(AircraftContext);
    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const {
        data: currentConfigData,
        isLoading: configIsLoading,
        refetch: refetchCurrentConfig,
    } = getCurrentConfig(selectedAircraftId, token, tokenExpiryDate);

    useEffect(() => {
        refetchCurrentConfig();
    }, [selectedAircraftId]);

    if (isFirstLoading) {
        return <LoadingSpinner fullPage={false} putLoginLabel={false} />;
    }
    return (
        <div
            className={styles.aircraft_configuration_sub_sub_content_container}
        >
            {!aircraftData && aircraftIsLoading ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : (
                <div className={styles.page}>
                    <div className={styles.page_container}>
                        <Inline spacing="4-x" alignY="start">
                            <Card className={styles.ac_card} translate>
                                <CardContent className={styles.card_content}>
                                    <div className={styles.card_remote_loading}>
                                        {aircraftData?.remoteLoadingTooltipMessage && (
                                            <div
                                                className={
                                                    styles.card_remote_loading_tooltip_container
                                                }
                                            >
                                                <Tooltip
                                                    className={
                                                        styles.card_remote_loading_tooltip
                                                    }
                                                    placement="top"
                                                    label={
                                                        <div>
                                                            <p>
                                                                {
                                                                    aircraftData
                                                                        ?.remoteLoadingTooltipMessage
                                                                        .message
                                                                }
                                                            </p>
                                                            {aircraftData
                                                                ?.remoteLoadingTooltipMessage
                                                                .error ?? (
                                                                <p>
                                                                    {
                                                                        aircraftData
                                                                            ?.remoteLoadingTooltipMessage
                                                                            .error
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    }
                                                >
                                                    {
                                                        aircraftData?.remoteLoadingIcon
                                                    }
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                    <img
                                        className={styles.card_plane}
                                        src={PlaneIcon}
                                        alt="plane"
                                    />
                                    <div className={styles.card_tail_id}>
                                        {aircraftData?.tailId}
                                    </div>
                                    <div className={styles.card_company}>
                                        <p className={styles.card_company_text}>
                                            {aircraftData?.company}
                                        </p>
                                    </div>
                                    <Divider className={styles.card_devider} />
                                    <div
                                        className={
                                            styles.card_icao_tailNumber_container
                                        }
                                    >
                                        <div>
                                            <Typography
                                                className={
                                                    styles.card_aircraft_data_labels
                                                }
                                                variant="small"
                                            >
                                                {i18n.t(
                                                    'aircraft.aircraftConfig.overview.icao'
                                                )}
                                            </Typography>
                                            <Typography
                                                className={
                                                    styles.card_aircraft_data
                                                }
                                                variant="h6"
                                            >
                                                {aircraftData?.icao}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography
                                            className={
                                                styles.card_aircraft_data_labels
                                            }
                                            variant="small"
                                        >
                                            {i18n.t(
                                                'aircraft.aircraftConfig.overview.intMsn'
                                            )}
                                        </Typography>
                                        <Typography
                                            className={
                                                styles.card_aircraft_data
                                            }
                                            variant="h6"
                                        >
                                            {aircraftData?.intMsn}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </Inline>
                    </div>
                </div>
            )}
            <div className={styles.currAppContentTitle}>
                {i18n.t('aircraft.aircraftConfig.overview.title')}
            </div>
            <div className={styles.current_conf_deployments_table}>
                {!aircraftData && aircraftIsLoading && configIsLoading ? (
                    <LoadingSpinner fullPage={false} putLoginLabel={false} />
                ) : (
                    <CurrentConfigTable apps={currentConfigData} />
                )}
            </div>
        </div>
    );
};
export default AircraftOverview;
