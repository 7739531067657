import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosPromise } from 'axios';
import i18n from 'i18next';
import {
    Button as AirbusButton,
    ColorModeProvider,
    Inline,
    Input,
    Typography,
    ComboBox,
    FormControl,
} from '@airbus/components-react';
import { Drawer } from '@mui/material';
import { CheckCircle, Delete } from '@airbus/icons/react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { AppContext } from '../../../AppContext';
import * as Utils from '../../../utils/ErrorUtils';
import { IApiError, IApiResponse } from '../../../models/AppModels';
import { apiUrl, isTokenExpired } from '../../../utils/ApplicationUtils';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../../redux/app/features/credentialsSlice';

import './SideNav.css';
import {
    Aircraft,
    AircraftCreationData,
    AircraftUpdateData,
} from '../../../models/Aircraft';
import {
    createAircraft as createAircraftViaApi,
    deleteAircraft as deleteAircraftViaApi,
    patchAircraft as patchAircraftViaApi,
} from '../../../services/api/AircraftApi';
import AircraftTitle from './AircraftTitle';
import { Company } from '../../../models/CompanyModel';
import { useQuery } from 'react-query';

type ResponsibleCompaniesData = {
    value: string;
    label: string;
};

type Props = {
    data?: Aircraft;
    onDataUpdated: () => void;
    onClose: () => void;
    isUpdateMode: boolean;
};

const SideNavAircraft = ({
    data,
    onDataUpdated,
    onClose,
    isUpdateMode,
}: Props) => {
    const { setMessageBanner } = useContext(AppContext);

    const [open, setOpen] = useState(true);

    const [tailId, setTailId] = useState(data?.tailId);
    const [airlineCompany, setAirlineCompany] = useState(data?.airlineCompany);
    const [intMsn, setIntMsn] = useState(data?.intMsn);
    const [responsibleCompanies, setResponsibleCompanies] = useState<
        ResponsibleCompaniesData[]
    >(
        (data?.responsibleCompanies ?? []).map((rc) => ({
            value: rc.id.toString(),
            label: rc.name,
        }))
    );

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const getCompanies = () => {
        const options = {
            headers: {
                Authorization: 'Bearer ' + (token ?? ''),
                'Content-Type': 'application/json;charset=UTF-8',
            },
        };
        return useQuery<Company[]>(
            ['get', 'company', 'ifeProvider'],
            () =>
                axios
                    .get(
                        `${apiUrl}/company?$sort[icao]=ASC&noIntern=true`,
                        options
                    )
                    .then(({ data }) => {
                        if (data.error) {
                            setMessageBanner({
                                isBanner: true,
                                message: `${data.error.message}`,
                                type: 'error',
                            });
                        } else {
                            return data.data;
                        }
                    }),
            {
                enabled: !isTokenExpired(tokenExpiryDate ?? 0),
                refetchOnWindowFocus: false,
                retry: false,
                onError: (err) => {
                    setMessageBanner({
                        isBanner: true,
                        message: Utils.displayErrorMessage(err as IApiError),
                        type: 'error',
                    });
                },
            }
        );
    };

    const { data: ifeProvidersData } = getCompanies();
    const airlines: Company[] = [];
    const ifeProviders: Company[] = [];
    if (ifeProvidersData) {
        for (const c of ifeProvidersData) {
            if (c.icao && c.icao !== '') {
                airlines.push(c);
            } else {
                ifeProviders.push(c);
            }
        }
    }

    const doAircraftApiCall = (
        call: () => AxiosPromise<any>,
        crudAction: 'create' | 'update' | 'delete'
    ) => {
        !isTokenExpired(tokenExpiryDate ?? 0) &&
            call()
                .then((res: IApiResponse) => {
                    if (res.data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: res.data.error.message,
                            type: 'error',
                        });
                    } else {
                        setMessageBanner({
                            isBanner: true,
                            message: `Aircraft was successfully ${crudAction}d`,
                            type: 'success',
                        });
                    }
                    onDataUpdated();
                })
                .catch((error: IApiError) => {
                    setMessageBanner({
                        isBanner: true,
                        message: Utils.displayErrorMessage(error),
                        type: 'error',
                    });
                });
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    const createAircraft = () => {
        if (airlineCompany && intMsn && tailId) {
            const newAircraft: AircraftCreationData = {
                intMsn,
                type: 'AIRLINE',
                tailId,
                airlineCompanyId: airlineCompany.id,
                responsibleCompanies: responsibleCompanies.map((rc) =>
                    Number(rc.value)
                ),
                model: 'A330',
            };
            doAircraftApiCall(() => {
                return createAircraftViaApi(token, newAircraft);
            }, 'create');
        }
    };

    const updateAircraft = () => {
        if (data) {
            const dataToUpdate: AircraftUpdateData = {
                intMsn: data.intMsn,
                tailId: data.tailId,
                airlineCompanyId: data.airlineCompanyId,
                responsibleCompanies: responsibleCompanies.map((rc) =>
                    Number(rc.value)
                ),
            };
            doAircraftApiCall(() => {
                return patchAircraftViaApi(token, data.id, dataToUpdate);
            }, 'update');
        }
    };

    const deleteAircraft = () => {
        if (data) {
            doAircraftApiCall(() => {
                return deleteAircraftViaApi(token, data.id);
            }, 'delete');
        }
    };

    const isCreateButtonEnabled = () => {
        return (
            tailId &&
            /^[a-zA-Z0-9]*$/gm.test(tailId) &&
            intMsn &&
            /^[a-zA-Z0-9]*$/gm.test(intMsn) &&
            airlineCompany
        );
    };

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 1 / 4,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    {<AircraftTitle handleClose={handleClose} />}
                </DialogTitle>
                <DialogContent>
                    <Typography className="input-label" variant="h6">
                        Tail Id
                    </Typography>
                    <FormControl
                        error={
                            tailId !== undefined &&
                            !/^[a-zA-Z0-9]*$/gm.test(tailId)
                        }
                        errorText={i18n.t('admin.aircrafts.dialog.tailError')}
                    >
                        <Input
                            onChange={(e: any) => {
                                setTailId(e.target.value);
                            }}
                            defaultValue={tailId}
                            disabled={isUpdateMode}
                        />
                    </FormControl>
                    <Typography className="input-label" variant="h6">
                        Airline ICAO
                    </Typography>
                    <ComboBox
                        disabled={isUpdateMode}
                        placeholder={
                            airlineCompany
                                ? airlineCompany.icao
                                : 'Choose a company'
                        }
                        options={
                            airlines
                                ? airlines.map((ad) => ({
                                      label: ad.icao,
                                      value: ad.id.toString(),
                                  }))
                                : []
                        }
                        onChange={(v) => {
                            if (Array.isArray(v)) {
                                return;
                            }
                            if (typeof v === 'string') {
                                return;
                            }

                            const company = airlines?.find(
                                (ad) => ad.id === Number(v.value)
                            );

                            if (!company) {
                                return;
                            }

                            setAirlineCompany({
                                icao: company.icao,
                                id: company.id,
                                name: company.name,
                            });
                        }}
                    />
                    <Typography className="input-label" variant="h6">
                        MSN
                    </Typography>
                    <FormControl
                        error={
                            intMsn !== undefined &&
                            !/^[a-zA-Z0-9]*$/gm.test(intMsn)
                        }
                        errorText={i18n.t('admin.aircrafts.dialog.msnError')}
                    >
                        <Input
                            onChange={(e: any) => {
                                setIntMsn(e.target.value);
                            }}
                            defaultValue={intMsn}
                            disabled={isUpdateMode}
                        />
                    </FormControl>
                    <div>
                        <Typography className="input-label" variant="h6">
                            IFE Providers
                        </Typography>
                        <br />
                        <ComboBox
                            disabled={
                                data?.type === 'VAL_CENTER' ||
                                data?.type === 'DEMO_REMOTE'
                            }
                            multiple
                            value={responsibleCompanies}
                            options={ifeProviders.map((p) => ({
                                value: p.id.toString(),
                                label: p.name,
                            }))}
                            onChange={(v) => {
                                console.log(v);
                                if (typeof v === 'string') {
                                    return;
                                }

                                if (Array.isArray(v)) {
                                    setResponsibleCompanies(v);
                                }
                            }}
                            placeholder="Providers"
                        />
                    </div>
                </DialogContent>
                <ColorModeProvider mode="dark">
                    <div className="dialog-footer">
                        <DialogActions>
                            {isUpdateMode ? (
                                <Inline spacing="1-x">
                                    <AirbusButton
                                        disabled={data?.type !== 'AIRLINE'}
                                        type="submit"
                                        icon={<Delete />}
                                        variant="error"
                                        onClick={() => {
                                            deleteAircraft();
                                        }}
                                    >
                                        {i18n.t(
                                            'admin.aircrafts.dialog.delete-button'
                                        )}
                                    </AirbusButton>
                                    <AirbusButton
                                        disabled={data?.type !== 'AIRLINE'}
                                        type="submit"
                                        icon={<CheckCircle />}
                                        variant="success"
                                        onClick={() => {
                                            updateAircraft();
                                        }}
                                    >
                                        {i18n.t(
                                            'admin.aircrafts.dialog.update-button'
                                        )}
                                    </AirbusButton>
                                </Inline>
                            ) : (
                                <AirbusButton
                                    disabled={!isCreateButtonEnabled()}
                                    className="button"
                                    type="submit"
                                    icon={<CheckCircle />}
                                    variant="success"
                                    onClick={() => {
                                        createAircraft();
                                    }}
                                >
                                    {i18n.t(
                                        'admin.aircrafts.dialog.create-button'
                                    )}
                                </AirbusButton>
                            )}
                        </DialogActions>
                    </div>
                </ColorModeProvider>
            </Drawer>
        </div>
    );
};

export default SideNavAircraft;
