import { DataTable, SearchInput, Button } from '@airbus/components-react';
import i18n from 'i18next';
import React, {
    SyntheticEvent,
    useCallback,
    useContext,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Column, SortingRule } from 'react-table';
import { IApiError } from '../../../models/AppModels';
import { Company, CompanyDataTable } from '../../../models/CompanyModel';
import {
    ApiCallbacks,
    displayAdminTextTranslation,
    useApiCall,
} from '../utils';
import * as Utils from '../../../utils/ErrorUtils';
import { AppContext } from '../../../AppContext';
import {
    companyToDataTable,
    dataTableToCompany,
} from '../../../utils/models/company.util';
import SideNavCompany from '../side-nav/SideNavCompany';

import './../Table.css';
import classes from './companyTable.module.css';

const columns: Column<CompanyDataTable>[] = [
    {
        Header: `${i18n.t('admin.companies.table.headerCell1')}`,
        accessor: 'name',
    },
    {
        Header: `${i18n.t('admin.companies.table.headerCell2')}`,
        accessor: 'icao',
    },
    {
        Header: `${i18n.t('admin.companies.table.headerCell3')}`,
        accessor: 'technicalName',
    },
    {
        Header: `${i18n.t('admin.companies.table.headerCell4')}`,
        accessor: 'equipedAC',
    },
    {
        Header: `${i18n.t('admin.companies.table.headerCell5')}`,
        accessor: 'userCount',
    },
];

type DataTableQuery<T extends Record<string, unknown> = {}> = {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<T>[];
};

const defaultSort = 'name';

interface Props {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
}

export const CompanyTable: React.FC<Props> = (props) => {
    // Error handling
    const { setMessageBanner } = useContext(AppContext);

    // DataTable
    /* DataTable props managed externally : data, loading, pageCount, rowCount */
    const [data, setData] = useState<CompanyDataTable[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [clickedData, setClickedData] = useState(-1);
    const { pathname } = useLocation();
    const [openSideNav, setOpenSideNav] = useState(false);
    const [sideNavDataUpdated, setSideNavDataUpdated] = useState<boolean>(true);

    const apiCallCallbacks: ApiCallbacks<Company> = {
        onSuccess: (data: Company[], total: number) => {
            const filteredCompanies: CompanyDataTable[] =
                data.map(companyToDataTable);

            setData(filteredCompanies);
            if (total !== rowCount) {
                setRowCount(total);
            }
            const newPageCount = Math.ceil(total / query.pageSize);
            if (newPageCount !== pageCount) {
                setPageCount(newPageCount);
            }
        },
        onError: (error: IApiError) => {
            setMessageBanner({
                isBanner: true,
                message: Utils.displayErrorMessage(error),
                type: 'error',
            });
        },
    };

    const [[query, setQuery], [setSearch], [loading]] = useApiCall(
        props.token,
        props.tokenExpiryDate,
        defaultSort,
        'company',
        apiCallCallbacks,
        sideNavDataUpdated
    );

    const onQueryUpdate = useCallback((queryParams: DataTableQuery) => {
        if (queryParams.sortBy.length > 1) {
            queryParams.sortBy.splice(0, queryParams.sortBy.length - 1);
        }
        setQuery(queryParams);
    }, []);

    const onRowClick = (row: CompanyDataTable): void => {
        const id = data.findIndex((d) => d.id === row.id);
        if (id === -1) {
            setMessageBanner({
                isBanner: true,
                message: `Unexpected error`,
                type: 'error',
            });
            return;
        }
        setClickedData(id);
        setOpenSideNav(true);
        setSideNavDataUpdated(false);
    };

    const onChange = (
        event: SyntheticEvent<any, Event>,
        value: string
    ): void => {
        setSearch(value);
        setQuery({ ...query, pageIndex: 0 });
    };

    const onDataUpdated = (): void => {
        setOpenSideNav(false);
        setSideNavDataUpdated(true);
    };

    return (
        <div className={classes.page}>
            <div className={classes.page_container}>
                <div className={classes.page_sub_container}>
                    <div className={classes.temp}>
                        <SearchInput placeholder="Search" onChange={onChange} />
                    </div>
                    <Button
                        variant="primary"
                        className={classes.add_company_button}
                        onClick={() => {
                            setSideNavDataUpdated(false);
                            setOpenSideNav(true);
                        }}
                    >
                        {displayAdminTextTranslation(pathname, 'button')}
                    </Button>
                </div>
                <div className="generic-table">
                    <DataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        onQueryUpdate={onQueryUpdate}
                        pageCount={pageCount}
                        rowCount={rowCount}
                        sortBy={query.sortBy}
                        onRowClick={onRowClick}
                    />
                </div>
                {/* TODO : Need to be a unique component */}
                {openSideNav && (
                    <div>
                        <SideNavCompany
                            data={
                                clickedData !== -1
                                    ? dataTableToCompany(data[clickedData])
                                    : undefined
                            }
                            isUpdateMode={clickedData !== -1}
                            onDataUpdated={onDataUpdated}
                            onClose={(): void => {
                                setOpenSideNav(false);
                                setClickedData(-1);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
