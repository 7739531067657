import React from 'react';
import i18n from 'i18next';
import '../../i18n/config';
import config from '../../config/config.json';
import { Button as AirbusButton, Typography } from '@airbus/components-react';
import { OpenInNew, PictureAsPdf } from '@airbus/icons/react';
import { URL_SUFFIX } from '../../constants/Urls';

const SdkDocumentation = () => {
    const sdkPdfUrl = config.urlPrefix + URL_SUFFIX.SDK_DOCS_PDF;

    return (
        <div className="tab-content">
            <Typography variant="h3">{i18n.t('sdkDoc.title')}</Typography>
            <Typography variant="small">
                {i18n.t('sdkDoc.content')}
                <br />
            </Typography>
            <span
                onClick={() => {
                    window.open(sdkPdfUrl);
                }}
            >
                <AirbusButton
                    icon={<PictureAsPdf />}
                    variant="primary"
                    className="margin-button"
                >
                    {i18n.t('sdkDoc.button1')}
                </AirbusButton>
            </span>
            <span
                onClick={() => {
                    window.open(config.urlPrefix + URL_SUFFIX.SDK_DOCS);
                }}
            >
                <AirbusButton
                    icon={<OpenInNew />}
                    variant="primary"
                    className="margin-button-right"
                >
                    {i18n.t('sdkDoc.button2')}
                </AirbusButton>
            </span>
        </div>
    );
};

export default SdkDocumentation;
