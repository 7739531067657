import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import i18n from 'i18next';
import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from 'react-query';
import '../../i18n/config';
import axios, { AxiosResponse, AxiosPromise } from 'axios';
import { isTokenExpired, apiUrl } from '../../utils/ApplicationUtils';
import { DataTableQuery } from './GenericTable';
import { IApiError } from '../../models/AppModels';
import { AppContext } from '../../AppContext';
import { Error200 } from '../../utils/ErrorUtils';

export const COMPANY = 'company';
export const USER = 'user';
export const ROLE = 'role';
export const AIRCRAFT = 'aircraft';

export const defaultPageSize = 10;

export const isPath = (pathname: string, path: string) => {
    const result = `/admin/${path}` === pathname;
    const resultRoot = `/admin` === pathname;
    return path === COMPANY ? result || resultRoot : result;
};

export const displayAdminTextTranslation = (
    pathname: string,
    element: string
) => {
    if (isPath(pathname, COMPANY)) return i18n.t(`admin.companies.${element}`);
    if (isPath(pathname, USER)) return i18n.t(`admin.users.${element}`);
    if (isPath(pathname, ROLE)) return i18n.t(`admin.roles.${element}`);
    if (isPath(pathname, AIRCRAFT)) return i18n.t(`admin.aircrafts.${element}`);

    return i18n.t(`admin.companies.${element}`);
};

export const tableAttributes = (pathname: string) => {
    if (isPath(pathname, COMPANY))
        return {
            name: '',
            icao: '',
            equipedAC: '',
            technicalName: '',
        };

    if (isPath(pathname, USER))
        return {
            name: '',
            loginId: '',
            company: '',
        };

    if (isPath(pathname, AIRCRAFT))
        return {
            tailId: '',
            icao: '',
            intMsn: '',
        };

    return {
        name: '',
        icao: '',
        equipedAC: '',
        technicalName: '',
    };
};

export const useCustomQuery = <T, E>(
    token: string | undefined,
    tokenExpiryDate: number | undefined,
    entity: string,
    enabled = true,
    encapsulatedData = true,
    sortingColumn = 'id',
    filterOn: Record<string, string> = {},
    onError: (err: any) => void | Promise<void> = () => {
        return;
    }
): UseQueryResult<T, E> => {
    const { setMessageBanner } = useContext(AppContext);
    const sortClause = `$sort[${sortingColumn}]`;

    const params: any = {};

    params[sortClause] = 1;

    if (Object.keys(filterOn).length) {
        Object.entries(filterOn).forEach(([key, value]) => {
            params[key] = value;
        });
    }

    const options = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8',
        },
        params: params,
    };

    return useQuery<T, E>(
        ['get', entity],
        () =>
            axios.get(`${apiUrl}/${entity}`, options).then(({ data }) => {
                if (data.error) {
                    return setMessageBanner({
                        isBanner: true,
                        message: data.error.message,
                        type: 'error',
                    });
                }
                return encapsulatedData ? data.data : data;
            }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0) && enabled,
            refetchOnWindowFocus: false,
            retry: false,
            onError: async (error) => {
                onError(error);
            },
        }
    );
};

export const useCustomMutation = <T, E>(
    token: string | undefined,
    tokenExpiryDate: number | undefined,
    entity: string,
    payload: any,
    method = 'post',
    onSuccess: (newEntity: AxiosResponse<T>) => void | Promise<void> = () => {
        return;
    },
    onError: (err: any) => void | Promise<void> = () => {
        return;
    }
): UseMutationResult<AxiosResponse<T | E>, unknown, void, unknown> => {
    const options = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };
    const { setMessageBanner } = useContext(AppContext);

    const url = `${apiUrl}/${entity}`;

    return useMutation<AxiosResponse>(
        () => {
            // TODO : I think there is na error here, the check for the token is not right
            return !isTokenExpired(tokenExpiryDate ?? 0) && method === 'post'
                ? axios.post(url, payload, options)
                : method === 'patch'
                ? axios.patch(url, payload, options)
                : axios.delete(url, options);
        },
        {
            retry: false,
            onSuccess: async (newEntity) => {
                if (newEntity.data.error) {
                    return setMessageBanner({
                        isBanner: true,
                        message: newEntity.data.error.message,
                        type: 'error',
                    });
                }
                onSuccess(newEntity);
            },
            onError: async (error) => {
                onError(error);
            },
        }
    );
};

export type ApiCallbacks<T extends {}> = {
    onSuccess: (data: T[], total: number, error?: Error200 | undefined) => void;
    onError: (error: IApiError) => void;
};

export type ApiCallMethod = (
    apiToken: string,
    params?: any
) => AxiosPromise<any>;

function call(
    apiToken: string,
    entity: string,
    setLoading: Dispatch<SetStateAction<boolean>>,
    params?: any
): AxiosPromise<any> {
    setLoading(true);
    const url = apiUrl + '/' + entity;

    const options = {
        headers: {
            Authorization: 'Bearer ' + apiToken,
        },
        params: params,
    };

    return axios.get(url, options);
}

export function useApiCall<T extends {}>(
    apiToken: string | undefined,
    tokenExpiryDate: number | undefined,
    defaultSort: string,
    entity: string,
    callbacks: ApiCallbacks<T>,
    sideNavDataUpdated: boolean,
    filters?: Record<string, string | null | undefined>
): any {
    const { setMessageBanner } = useContext(AppContext);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState<DataTableQuery>({
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy: [],
    });
    const location: any = useLocation();
    const sortLiteral =
        query.sortBy.length === 1
            ? `$sort[${query.sortBy[0].id}]`
            : `$sort[${defaultSort}]`;
    const params = {
        $skip: query.pageIndex * query.pageSize,
        $limit: query.pageSize,
        [sortLiteral]:
            query.sortBy.length === 1
                ? query.sortBy[0].desc
                    ? 'DESC'
                    : 'ASC'
                : 'ASC', // ASC:1, DESC: -1
    };

    if (search) {
        params['$like'] = `%${search}%`;
    }
    if (filters && Object.keys(filters).length) {
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                params[key] = value;
            }
        });
    }
    useDeepCompareEffect(() => {
        if (!isTokenExpired(tokenExpiryDate ?? 0)) {
            // define user roleId as null implying effect hook has run after component is mounted
            // so avoid duplicate api calls on effect hook
            (entity !== 'user' || filters?.roleId !== undefined) &&
                sideNavDataUpdated &&
                call(apiToken ?? '', entity, setLoading, params)
                    .then((response) => {
                        setLoading(false);
                        if (response.data.error) {
                            return setMessageBanner({
                                isBanner: true,
                                message: response.data.error.message,
                                type: 'error',
                            });
                        }
                        callbacks.onSuccess(
                            response.data.data,
                            response.data.total,
                            response.data.error
                        );
                        return [[query, setQuery], [setSearch]];
                    })
                    .catch((error: IApiError) => {
                        callbacks.onError(error);
                        return [
                            [query, setQuery],
                            [search, setSearch],
                        ];
                    });
        }
    }, [search, query, filters?.roleId, location.state, sideNavDataUpdated]);
    return [[query, setQuery], [setSearch], [loading]];
}
