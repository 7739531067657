import React, { useContext } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { Button as AirbusButton, Inline } from '@airbus/components-react';
import { Cancel, CheckCircle, Edit, Delete } from '@airbus/icons/react';
import i18n from 'i18next';

import RoleForm from './RoleForm';
import { RoleContext } from './RoleContext';

import './RoleComponent.css';

type Props = {
    selectedRole: { value: string; label: string };
    roleUsers: number[] | undefined;
    onClickSave: () => void;
    onClickDelete: () => void;
    onClickUsers: () => void;
    onChangeRoleLabel: (e: string) => void;
    newLabel: string;
};

export const RoleUserDetails = ({
    selectedRole,
    roleUsers,
    onClickSave,
    onClickDelete,
    onClickUsers,
    onChangeRoleLabel,
    newLabel,
}: Props) => {
    const { editMode, setEditMode } = useContext(RoleContext);

    const { label } = selectedRole;
    const userCount = roleUsers ? roleUsers.length : 0;
    const userCountDisplay = userCount > 1 ? 'users' : 'user';

    return (
        <div className="role-user-container">
            <div className="role-user-sub-container">
                {
                    <div>
                        {editMode ? (
                            <div
                                style={editMode ? { height: '70%' } : undefined}
                            >
                                <RoleForm
                                    onChangeLabel={(lab: string) =>
                                        onChangeRoleLabel(lab)
                                    }
                                    label={newLabel}
                                    displayLabel={false}
                                />
                            </div>
                        ) : (
                            <div className="role-name">{label}</div>
                        )}
                    </div>
                }
                <div className="role-users-number" onClick={onClickUsers}>
                    {`${userCount ? userCount : '0'} ${userCountDisplay}`}{' '}
                </div>
            </div>
            <div className="role-user-dialog">
                <DialogActions>
                    <Inline spacing="1-x">
                        {editMode ? (
                            <DialogActions>
                                <SaveButtons
                                    onClickSave={onClickSave}
                                    disabled={!newLabel}
                                />
                                <AirbusButton
                                    type="submit"
                                    icon={<Cancel />}
                                    variant="error"
                                    onClick={() => setEditMode(false)}
                                >
                                    {i18n.t('admin.roles.dialog.cancel-button')}
                                </AirbusButton>
                            </DialogActions>
                        ) : (
                            <EditButtons
                                onClickEdit={() => setEditMode(true)}
                                onDelete={onClickDelete}
                            />
                        )}
                    </Inline>
                </DialogActions>
            </div>
        </div>
    );
};

const EditButtons = ({
    onClickEdit,
    onDelete,
}: {
    onClickEdit: () => void;
    onDelete: () => void;
}) => {
    return (
        <DialogActions>
            <Inline spacing="1-x">
                <AirbusButton
                    type="submit"
                    icon={<Edit />}
                    variant="primary"
                    onClick={() => {
                        onClickEdit();
                    }}
                >
                    {i18n.t('admin.roles.dialog.update-button')}
                </AirbusButton>
                <AirbusButton
                    type="submit"
                    icon={<Delete />}
                    variant="error"
                    onClick={() => {
                        onDelete();
                    }}
                >
                    {i18n.t('admin.roles.dialog.delete-button')}
                </AirbusButton>
            </Inline>
        </DialogActions>
    );
};

const SaveButtons = ({
    onClickSave,
    disabled,
}: {
    onClickSave: () => void;
    disabled: boolean;
}) => {
    return (
        <DialogActions>
            <Inline spacing="1-x">
                <AirbusButton
                    disabled={disabled}
                    type="submit"
                    icon={<CheckCircle />}
                    variant="success"
                    onClick={() => {
                        onClickSave();
                    }}
                >
                    {i18n.t('admin.roles.dialog.save-button')}
                </AirbusButton>
            </Inline>
        </DialogActions>
    );
};
