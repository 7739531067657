import React from 'react';
import i18n from 'i18next';
import { OpenInNew } from '@airbus/icons/react';
import { Button, Typography } from '@airbus/components-react';

import '../../i18n/config';
import { envStage } from '../../utils/ApplicationUtils';
import {
    IdeLinks,
    DataSimuLinks,
    PaxSimuLinks,
    CrewSimuLinks,
    LoadingSimuLinks,
} from '../../constants/EnvStage';

const ideLink: string = IdeLinks[envStage];
const dataSimuLink: string = DataSimuLinks[envStage];
const paxSimuLink: string = PaxSimuLinks[envStage];
const crewSimuLink: string = CrewSimuLinks[envStage];
const loadingSimuLink: string = LoadingSimuLinks[envStage];

const S3Download = () => {
    return (
        <div>
            <div className="tab-content">
                <Typography variant="h3">
                    {i18n.t('s3Download.title')}
                </Typography>
                <Typography variant="small">
                    {i18n.t('s3Download.content')}
                </Typography>
                <span
                    onClick={() => {
                        window.open(ideLink);
                    }}
                >
                    <Button
                        icon={<OpenInNew />}
                        variant="primary"
                        className="margin-button"
                    >
                        {i18n.t('s3Download.buttonIde')}
                    </Button>
                </span>
                <span
                    onClick={() => {
                        window.open(dataSimuLink);
                    }}
                >
                    <Button
                        icon={<OpenInNew />}
                        variant="primary"
                        className="margin-button-right"
                    >
                        {i18n.t('s3Download.buttonData')}
                    </Button>
                </span>
                <span
                    onClick={() => {
                        window.open(paxSimuLink);
                    }}
                >
                    <Button
                        icon={<OpenInNew />}
                        variant="primary"
                        className="margin-button-right"
                    >
                        {i18n.t('s3Download.buttonPax')}
                    </Button>
                </span>
                <span
                    onClick={() => {
                        window.open(crewSimuLink);
                    }}
                >
                    <Button
                        icon={<OpenInNew />}
                        variant="primary"
                        className="margin-button-right"
                    >
                        {i18n.t('s3Download.buttonCrew')}
                    </Button>
                </span>
                <span
                    onClick={() => {
                        window.open(loadingSimuLink);
                    }}
                >
                    <Button
                        icon={<OpenInNew />}
                        variant="primary"
                        className="margin-button-right"
                    >
                        {i18n.t('s3Download.buttonLoading')}
                    </Button>
                </span>
            </div>
        </div>
    );
};

export default S3Download;
