import React from 'react';
import { Chip, Inline, Tooltip } from '@airbus/components-react';

type Props = {
    strings: string[];
};

const MultipleChips = ({ strings }: Props) => {
    const numberOfStrings = strings.length;
    // default Array.sort() is case sensitive, but we need case insensitive sorting
    const sortedStrings = strings.sort((a, b) =>
        a.localeCompare(b, undefined, { sensitivity: 'base' })
    );
    if (numberOfStrings < 1) {
        return <></>;
    }

    if (numberOfStrings > 2) {
        const remainingRoles = strings
            .slice(2)
            .reduce((prev: string, curr: string) => `${prev}, ${curr}`, '')
            .replace(', ', '');
        return (
            <Inline spacing="2-x">
                <Chip label={`${sortedStrings[0]}`} variant="info" />
                <Chip label={`${sortedStrings[1]}`} variant="info" />
                <Tooltip placement="top" label={`${remainingRoles}`}>
                    <Chip label={`+${numberOfStrings - 2}`} variant="info" />
                </Tooltip>
            </Inline>
        );
    } else {
        return (
            <Inline spacing="2-x">
                {sortedStrings.map((str: string, index: number) => (
                    <Chip key={index} label={`${str}`} variant="info" />
                ))}
            </Inline>
        );
    }
};

export default MultipleChips;
