import axios, { AxiosPromise } from 'axios';
import {
    CompanyCreationData,
    CompanyUpdateData,
} from '../../models/CompanyModel';
import { apiUrl } from '../../utils/ApplicationUtils';

/**
 * Method to fetch companies from DB
 * @param apiToken JWT token to allow api call
 * @param params Query parameters (skip for pagination & sort)
 * @returns
 */
export function getCompanies(
    apiToken: string | undefined,
    params?: any
): AxiosPromise<any> {
    const url = apiUrl + '/company';

    const options = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
        params: params,
    };

    return axios.get(url, options);
}

export function createCompany(
    apiToken: string | undefined,
    payload: CompanyCreationData
): AxiosPromise<any> {
    const url = apiUrl + '/company';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
    };

    return axios.post(url, payload, headers);
}

export function deleteCompany(
    apiToken: string | undefined,
    id: number
): AxiosPromise<any> {
    const url = apiUrl + `/company/${id}`;

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
    };

    return axios.delete(url, headers);
}

export function updateCompany(
    apiToken: string | undefined,
    id: number,
    data: CompanyUpdateData
): AxiosPromise<any> {
    const url = apiUrl + `/company/${id}`;

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
    };

    return axios.put(url, data, headers);
}
