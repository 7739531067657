/* eslint-disable max-len */
import * as React from 'react';

function TrashIcon() {
    return (
        <svg
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m402.5 52.5v52.5h-297.5v35h297.5v52.5h35v-140zm70 52.5v35h122.5v-35zm-210 105v52.5h-157.5v35h157.5v52.5h35v-140zm70 52.5v35h262.5v-35zm70 105v52.5h-297.5v35h297.5v52.5h35v-140zm70 52.5v35h122.5v-35z" />
        </svg>
    );
}

export default TrashIcon;
