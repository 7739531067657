import React, { useState, useContext } from 'react';
import i18n from 'i18next';

import { UserRoleTable } from './UserRoleTable';
import { UserDataTable } from '../../../models/UserModel';
import { AppContext } from '../../../AppContext';
import {
    Button,
    ColorModeProvider,
    IconButton,
    Typography,
} from '@airbus/components-react';
import { CheckCircle, Close } from '@airbus/icons/react';
import { Drawer, DialogActions, DialogTitle } from '@mui/material';

import { useCustomMutation } from '../utils';
import { displayErrorMessage } from '../../../utils/ErrorUtils';
import { IApiError } from '../../../models/AppModels';

import './userRoleUpdate.css';

type Props = {
    onClose: () => void;
    onSuccess: () => void;
    selectedUser: UserDataTable | undefined;
    token: string | undefined;
    tokenExpiryDate: number | undefined;
};

export const UserRoleUpdate = ({
    token,
    tokenExpiryDate,
    onClose,
    onSuccess,
    selectedUser,
}: Props) => {
    const [open, setOpen] = useState(true);
    const { setMessageBanner } = useContext(AppContext);

    const roleIds = selectedUser?.roleIds;
    const userId = selectedUser?.id;

    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [validationDisable, setValidationDisable] = useState<boolean>(true);

    const { mutate: editUser } = useCustomMutation(
        token,
        tokenExpiryDate,
        `user/${userId}`,
        { id: userId, roles: selectedRoles },
        'patch',
        async () => {
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.users.success.edit'),
                type: 'success',
            });
            onSuccess();
        },
        (err) => {
            setMessageBanner({
                isBanner: true,
                message: displayErrorMessage(err as IApiError),
                type: 'error',
            });
            onCloseFun();
        }
    );

    const onCloseFun = () => {
        setValidationDisable(true);
        setOpen(false);
        onClose();
    };

    return (
        <div>
            <Drawer
                anchor={'right'}
                onClose={onCloseFun}
                open={open}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 1 / 4,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'inline-block', width: '100%' }}>
                        <div style={{ float: 'left' }}>
                            User
                            <br />
                            <Typography variant="medium">
                                {selectedUser?.name}
                            </Typography>
                        </div>
                        <div style={{ float: 'right' }} onClick={onCloseFun}>
                            <IconButton size="small" variant="ghost">
                                <Close onClick={onCloseFun} />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <UserRoleTable
                    token={token}
                    tokenExpiryDate={tokenExpiryDate}
                    userWithRoles={roleIds}
                    onChangeRoles={setSelectedRoles}
                    setValidationDisable={setValidationDisable}
                />
                <ColorModeProvider mode="dark">
                    <div className="dialog-footer">
                        <DialogActions>
                            <Button
                                disabled={validationDisable}
                                type="submit"
                                icon={<CheckCircle />}
                                variant="success"
                                onClick={() => {
                                    editUser();
                                }}
                            >
                                {i18n.t('admin.companies.dialog.update-button')}
                            </Button>
                        </DialogActions>
                    </div>
                </ColorModeProvider>
            </Drawer>
        </div>
    );
};
