import i18n from 'i18next';
import { Aircraft as AircraftModel } from '../../models/Aircraft';
import { IApiError } from '../../models/AppModels';
import { DeliveryTarget } from '../../models/DeliveryBundle';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';
import { handleDates } from '../../utils/DateUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';
import { snakeCaseToCamelCase } from '../../utils/TypeUtils';
import { useQuery } from 'react-query';
import { Error, Sync, Time } from '@airbus/icons/react';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../redux/app/features/credentialsSlice';
import { AppContext } from '../../AppContext';
import { LightAircraft, RemoteLoadingMessage } from './types';
import config from '../../config/config.json';
const remoteLoadingStateOrder = {
    ERROR: 3,
    SYNC_ERROR: 3,
    CANCELLED: 2,
    SYNC_SUCCESS: 2,
    SYNC_STARTED: 1,
    REQUESTED: 0,
    SQS_PUSHED: 0,
};

type AircraftFilters = {
    search?: string;
};

export function getAircrafts(
    filters: AircraftFilters,
    setIsFirstLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);
    const envStage = config?.stage;
    const { setMessageBanner } = useContext(AppContext);

    const options: AxiosRequestConfig = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8',
        },
        params: {
            addResponsibleAc: true,
            addRemoteLoadingState: true,
            filterByCompany: true,
            type: 'AIRLINE',
            addDemoAircrafts: envStage === 'dev',
            "$sort['tailId']": 'ASC',
        },
    };

    if (filters.search) {
        options.params['$like'] = `%${filters.search}%`;
    }

    return useQuery<LightAircraft[]>(
        ['get', 'aircraft', filters],
        () =>
            axios.get(`${apiUrl}/aircraft`, options).then(({ data }) => {
                setIsFirstLoading(false);
                if (data.error) {
                    setMessageBanner({
                        isBanner: true,
                        message: `${data.error.message}`,
                        type: 'error',
                    });
                    return [];
                } else {
                    if (data.data) {
                        const aircrafts = data.data
                            .map(handleDates)
                            .map((ac: any) =>
                                snakeCaseToCamelCase<AircraftModel>(ac)
                            ) as AircraftModel[];
                        return aircrafts.map((ac): LightAircraft => {
                            let target =
                                ac.deliveryTargets &&
                                ac.deliveryTargets.length > 0
                                    ? ac.deliveryTargets[0]
                                    : undefined;

                            if (target) {
                                target = snakeCaseToCamelCase<DeliveryTarget>(
                                    target
                                ) as DeliveryTarget;
                            }
                            type Temp = {
                                order: number;
                                errorMessage?: string;
                            };
                            const mainEvent: Temp = target
                                ? target.deliveryEvents.reduce<Temp>(
                                      (state, curr) => {
                                          const order =
                                              remoteLoadingStateOrder[
                                                  curr.status
                                              ];
                                          if (order > state.order) {
                                              if (order === 3) {
                                                  return {
                                                      order,
                                                      errorMessage:
                                                          curr.userMessage ??
                                                          'No specific error message',
                                                  };
                                              }
                                              return { order };
                                          }
                                          return state;
                                      },
                                      { order: -1 }
                                  )
                                : { order: -1 };

                            if (mainEvent.order === -1) {
                                mainEvent.order = 2;
                            }

                            let remoteLoadingIcon: JSX.Element | undefined =
                                undefined;
                            let remoteLoadingMessage:
                                | RemoteLoadingMessage
                                | undefined = undefined;
                            if (mainEvent.order === 0) {
                                remoteLoadingIcon = (
                                    <Time
                                        color="#002466"
                                        height={15}
                                        width={15}
                                    />
                                );
                                remoteLoadingMessage = {
                                    message: i18n.t(
                                        'aircraft.aircraftView.tooltip_remote_loading_to_process'
                                    ),
                                };
                            } else if (mainEvent.order === 1) {
                                remoteLoadingIcon = (
                                    <Sync
                                        color="#002466"
                                        height={15}
                                        width={15}
                                    />
                                );
                                remoteLoadingMessage = {
                                    message: i18n.t(
                                        'aircraft.aircraftView.tooltip_remote_loading_processing'
                                    ),
                                };
                            } else if (mainEvent.order === 3) {
                                remoteLoadingIcon = (
                                    <Error
                                        color="#E93254"
                                        height={15}
                                        width={15}
                                    />
                                );
                                remoteLoadingMessage = {
                                    message: i18n.t(
                                        'aircraft.aircraftView.tooltip_remote_loading_error'
                                    ),
                                    error: mainEvent.errorMessage,
                                };
                            }

                            return {
                                id: ac.id,
                                tailId: ac.tailId,
                                intMsn: ac.intMsn,
                                icao: ac.airlineCompany?.icao,
                                company: ac.airlineCompany?.name ?? 'error',
                                remoteLoadingIcon: remoteLoadingIcon,
                                remoteLoadingTooltipMessage:
                                    remoteLoadingMessage,
                            };
                        });
                    }
                    return [];
                }
            }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0),
            refetchOnWindowFocus: false,
            retry: false,
            onError: (err) => {
                setMessageBanner({
                    isBanner: true,
                    message: displayErrorMessage(err as IApiError),
                    type: 'error',
                });
            },
        }
    );
}
export default getAircrafts;
