import { User, UserDataTable } from '../../models/UserModel';

export function userToDataTable(user: User): UserDataTable {
    return {
        ...user,
        company: user.company?.name ?? '',
        roles: user.roles.map((x: { label: string }) => x.label),
        roleIds: user.roles.map((x: { id: number }) => x.id),
    };
}
