import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Typography } from '@airbus/components-react';
import { CurrentConfig } from '../../../../models/CurrentConfig';
import LoginSpinner from '../../../loaders/LoginSpinner';
import styles from '../AircraftConfiguration.module.css';
import DataTable from './DataTable';
type Props = {
    handleRefreshAppList?: () => void;
    apps?: CurrentConfig[] | undefined;
};

const CurrentConfigTable = (props: Props) => {
    const [data, setData] = useState<CurrentConfig[] | undefined>([]);
    useEffect(() => {
        setData(props.apps);
    }, [props.apps]);

    if (!data) {
        return (
            <div className={styles.deployments_table}>
                <LoginSpinner fullPage={false} putLoginLabel={false} />
            </div>
        );
    } else if (data && data?.length > 0) {
        return <DataTable apps={data} />;
    } else {
        return (
            <div className={styles.content_deployments}>
                <Typography variant="large" id="title">
                    {i18n.t(
                        'aircraft.aircraftConfig.deploymenthistory.noApps.title'
                    )}
                </Typography>
            </div>
        );
    }
};

export default CurrentConfigTable;
