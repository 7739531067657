import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    ColorModeProvider,
    Typography,
} from '@airbus/components-react';
import { Box, Tab, Tabs } from '@mui/material';
import i18n from 'i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import AircraftDeploymentHistory from './deployments-history/AircraftDeploymentHistory';
import AircraftOverview from './overview/AircraftOverview';
import LoadingSpinner from '../../loaders/LoginSpinner';
import { AircraftOptionsData, LightAircraft } from '../types';
import getAircrafts from '../utils';
import { AircraftSelect } from './AircraftSelect';
import { AircraftContext } from '../AircraftContext';
import styles from './AircraftConfiguration.module.css';
import { ArrowBack } from '@airbus/icons/react';

const AircraftConfiguration = () => {
    const history = useHistory();
    const [aircraftOptionsData, setAircraftOptionsData] = useState<
        AircraftOptionsData[] | undefined
    >();
    const { id } = useParams<{ id: string }>();

    const [selectedAircraftId, setSelectedAircraftId] = useState<
        number | undefined
    >(Number(id));

    const [aircraftData, setAircraftData] = useState<
        LightAircraft | undefined
    >();
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

    const { data: aircrafts, isLoading: aircraftIsLoading } = getAircrafts(
        {},
        setIsFirstLoading
    );

    useMemo(() => {
        const aircraftData: LightAircraft | undefined = aircrafts?.find(
            (ac) => ac.id === Number(selectedAircraftId)
        );
        setAircraftData(aircraftData);
        const optionsData: AircraftOptionsData[] | undefined = aircrafts?.map(
            ({ id, tailId }) => ({
                id,
                tailId,
            })
        );
        setAircraftOptionsData(optionsData);
    }, [aircrafts]);

    useEffect(() => {
        const aircraftData: LightAircraft | undefined = aircrafts?.find(
            (ac) => ac.id === Number(selectedAircraftId)
        );
        setAircraftData(aircraftData);
        const optionsData: AircraftOptionsData[] | undefined = aircrafts?.map(
            ({ id, tailId }) => ({
                id,
                tailId,
            })
        );
        setAircraftOptionsData(optionsData);

        history.push({
            pathname: `/aircraft/aircraft-configuration/overview/${selectedAircraftId}`,
            state: { roleId: null, roleName: null },
        });
    }, [selectedAircraftId]);

    const [selectedTab, setSelectedTab] = useState<
        'overview' | 'deployment-history' | 'aircraft' | undefined
    >();

    useEffect(() => {
        switch (window.location.pathname) {
            case '/aircraft':
                history.push({
                    pathname: '/aircraft/aircraft-view',
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('aircraft');
                break;
            case '/aircraft/aircraft-configuration/deployment-history/:id':
                history.push({
                    pathname: `/aircraft/aircraft-configuration/deployment-history/${selectedAircraftId}`,
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('deployment-history');
                break;
            case '/aircraft/aircraft-configuration/overview/:id':
                history.push({
                    pathname: `/aircraft/aircraft-configuration/overview/${selectedAircraftId}`,
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('overview');
                break;
            default:
                history.push({
                    pathname: `/aircraft/aircraft-configuration/overview/${selectedAircraftId}`,
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('overview');
                break;
        }
    }, []);

    return (
        <>
            {selectedTab === undefined ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : (
                <AircraftContext.Provider
                    value={{
                        selectedAircraftId,
                        setAircraft: (aircraftId: number | undefined) =>
                            setSelectedAircraftId(aircraftId),
                    }}
                >
                    <div className={styles.aircraft_configuration_container}>
                        <ColorModeProvider mode="dark">
                            <div className="bg-primaryblue-90 main-page-title">
                                <div className="text-left">
                                    <Typography variant="h2">
                                        {i18n.t('aircraft.title')}
                                    </Typography>
                                </div>
                            </div>
                        </ColorModeProvider>

                        <div
                            className={
                                styles.aircraft_configuration_content_container
                            }
                        >
                            <div>
                                <div className={styles.back_button}>
                                    <Button
                                        icon={<ArrowBack />}
                                        onClick={() => {
                                            history.push(`/aircraft`);
                                        }}
                                        variant="ghost"
                                    >
                                        Back to list
                                    </Button>
                                </div>
                                {aircrafts && (
                                    <div className={styles.select_container}>
                                        <AircraftSelect
                                            options={aircraftOptionsData}
                                        />
                                    </div>
                                )}
                            </div>
                            <div
                                className={
                                    styles.aircraft_configuration_sub_content_container
                                }
                            >
                                <Box sx={{ flexGrow: 1, flex: '0 0' }}>
                                    <Tabs
                                        sx={{
                                            borderRight: 1,
                                            borderColor: 'divider',
                                            textAlign: 'left',
                                            width: '200px',
                                        }}
                                        orientation="vertical"
                                        value={selectedTab}
                                        onChange={(event, value) => {
                                            history.push({
                                                pathname: value,
                                                state: {
                                                    roleId: null,
                                                    roleName: null,
                                                },
                                            });
                                            setSelectedTab(value);
                                        }}
                                    >
                                        <Tab
                                            label={`${i18n.t(
                                                'aircraft.overview_tab'
                                            )}`}
                                            component={Link}
                                            to={`/aircraft/aircraft-configuration/overview/${id}`}
                                            value={'overview'}
                                        />
                                        <Tab
                                            label={`${i18n.t(
                                                'aircraft.deployment_history_tab'
                                            )}`}
                                            component={Link}
                                            to={`/aircraft/aircraft-configuration/deployment-history/${id}`}
                                            value={'deployment-history'}
                                        />
                                    </Tabs>
                                </Box>
                                {selectedTab === 'overview' ? (
                                    <AircraftOverview
                                        isFirstLoading={isFirstLoading}
                                        aircraftData={aircraftData}
                                        aircraftIsLoading={aircraftIsLoading}
                                    />
                                ) : (
                                    <AircraftDeploymentHistory
                                        isFirstLoading={isFirstLoading}
                                        aircraftData={aircraftData}
                                        aircraftIsLoading={aircraftIsLoading}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </AircraftContext.Provider>
            )}
        </>
    );
};

export default AircraftConfiguration;
