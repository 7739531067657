import { DataTableQuery } from '../components/admin/GenericTable';
import { HistoryDeliveryBundleStatus } from '../models/AircraftDeliveryBundle';
import i18n from 'i18next';
export const getSortForDataTable = (
    query: DataTableQuery,
    defaultSort: string
) => {
    const sortLiteral =
        query.sortBy.length === 1
            ? `$sort[${query.sortBy[0].id}]`
            : `$sort[${defaultSort}]`;
    return {
        $skip: query.pageIndex * query.pageSize,
        $limit: query.pageSize,
        [sortLiteral]:
            query.sortBy.length === 1
                ? query.sortBy[0].desc
                    ? 'DESC'
                    : 'ASC'
                : 'ASC', // ASC:1, DESC: -1
    };
};

export const beautifyStatus = (status: HistoryDeliveryBundleStatus): string => {
    switch (status) {
        case 'REQUESTED':
            return i18n.t('deployment.history.table.status.planned');
        case 'SQS_PUSHED':
            return i18n.t('deployment.history.table.status.planned');
        case 'SYNC_STARTED':
            return i18n.t('deployment.history.table.status.inProgress');
        case 'SYNC_SUCCESS':
            return i18n.t('deployment.history.table.status.completed');
        case 'CANCELLED':
            return i18n.t('deployment.history.table.status.cancelled');
        case 'SYNC_ERROR':
            return i18n.t('deployment.history.table.status.error');
        case 'ERROR':
            return i18n.t('deployment.history.table.status.error');
        default:
            return i18n.t('deployment.history.table.status.default');
    }
};
