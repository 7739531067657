import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { RootState } from '../store';

type InitialState = {
    token: string | undefined;
    cookie: string | undefined;
    tokenExpiryDate: number | undefined;
};

const initialState: InitialState = {
    token: undefined,
    cookie: undefined,
    tokenExpiryDate: undefined,
};

type CookieAction = {
    expiryDate: Date | undefined;
    token: string;
};

export const appSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setToken: (state: InitialState, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setCookie: (
            state: InitialState,
            action: PayloadAction<CookieAction>
        ) => {
            const cookie = new Cookies();
            const expiry = action.payload.expiryDate || new Date();
            cookie.set('token', action.payload.token, {
                path: '/',
                expires: expiry,
            });

            state.cookie = cookie.get('token');
        },
        setTokenExpiryDate: (
            state: InitialState,
            action: PayloadAction<number>
        ) => {
            state.tokenExpiryDate = action.payload;
        },
    },
});

export const { setToken, setCookie, setTokenExpiryDate } = appSlice.actions;

export const selectToken = (state: RootState) => state.credentials.token;
export const selectCookie = (state: RootState) => state.credentials.cookie;
export const selectTokenExpiryDate = (state: RootState) =>
    state.credentials.tokenExpiryDate;

export default appSlice.reducer;
