import React from 'react';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ColorModeProvider, Typography } from '@airbus/components-react';

import '../../i18n/config';
import { CompanyTable } from './companies/CompanyTable';
import RolePage from './role/RolePage';
import { UserTable } from './user/UserTable';
import { computePermissions } from '../../utils/permission';
import AdminTabs from './AdminTabs';
import { useSelector } from 'react-redux';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../redux/app/features/credentialsSlice';
import LoadingSpinner from '../loaders/LoginSpinner';
import { AircraftTable } from './aircraft/AircraftTable';

const Admin = () => {
    const [selectedTab, setSelectedTab] = useState<string>('');

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const adminPermissions = computePermissions();

    useEffect(() => {
        if (window.location.pathname === '/admin') {
            if (adminPermissions.adminCompany) {
                setSelectedTab('/admin/company');
            } else if (adminPermissions.adminRole) {
                setSelectedTab('/admin/role');
            } else if (adminPermissions.user) {
                setSelectedTab('/admin/user');
            } else if (adminPermissions.adminAircraft) {
                setSelectedTab('/admin/aircraft');
            }
        } else setSelectedTab(window.location.pathname);
    }, []);

    // TODO : This way of doing things is so bad
    // When we have time we need to remove the admin component,
    // and only keep user, role and company
    // And we need to had the tabs to each of them
    const componentToRender: JSX.Element =
        selectedTab === '' ? (
            <LoadingSpinner />
        ) : selectedTab === '/admin/company' &&
          adminPermissions.adminCompany ? (
            <CompanyTable token={token} tokenExpiryDate={tokenExpiryDate} />
        ) : selectedTab === '/admin/role' && adminPermissions.adminRole ? (
            <RolePage setSelectedTab={setSelectedTab} />
        ) : selectedTab === '/admin/user' && adminPermissions.user ? (
            <UserTable token={token} tokenExpiryDate={tokenExpiryDate} />
        ) : selectedTab === '/admin/aircraft' &&
          adminPermissions.adminAircraft ? (
            <AircraftTable token={token} tokenExpiryDate={tokenExpiryDate} />
        ) : (
            <Redirect to="/getting-started" />
        );

    return (
        <div className="admin-container">
            <ColorModeProvider mode="dark">
                <div className="bg-primaryblue-90 main-page-title">
                    <div className="text-left">
                        <Typography variant="h2">
                            {i18n.t('admin.title')}
                        </Typography>
                    </div>
                </div>
            </ColorModeProvider>
            <div className="admin-content-container">
                {selectedTab !== '' && (
                    <AdminTabs
                        selectedTab={selectedTab}
                        onTabChange={(value: string) => {
                            setSelectedTab(value);
                        }}
                        adminpermission={adminPermissions}
                    />
                )}
                {componentToRender}
            </div>
        </div>
    );
};

export default Admin;
