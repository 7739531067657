import i18n from 'i18next';
import { IDeploymentHistoryHeadCell } from '../components/aircraft-view/types';
import '../i18n/config';

const RED = '#eb2448';
const BLUE = '#61b7e8';
const ORANGE = '#ff9232';

export const NEW = 'new';
export const UPDATED = 'updated';
export const REMOVED = 'removed';

export const COMPONENT_STATUS: any = (state: string) => {
    let style = {
        LABEL: '',
        COLOR: '',
    };
    if (state === UPDATED) {
        style = {
            LABEL: `${i18n.t(
                'aircraft.aircraftConfig.deploymenthistory.table.status1'
            )}`,
            COLOR: ORANGE,
        };
    }

    if (state === REMOVED) {
        style = {
            LABEL: `${i18n.t(
                'aircraft.aircraftConfig.deploymenthistory.table.status2'
            )}`,
            COLOR: RED,
        };
    }
    if (state === NEW) {
        style = {
            LABEL: `${i18n.t(
                'aircraft.aircraftConfig.deploymenthistory.table.status3'
            )}`,
            COLOR: BLUE,
        };
    }
    return style;
};

export const DEPLOYMENT_HISTORY_HEAD_CELLS: IDeploymentHistoryHeadCell[] = [
    {
        id: 'loading',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell1'
        )}`,
    },
    {
        id: 'upload',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell2'
        )}`,
    },
    {
        id: 'startDate',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell3'
        )}`,
    },
    {
        id: 'endtDate',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell4'
        )}`,
    },
    {
        id: 'applications',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell5'
        )}`,
    },
    {
        id: 'sizeOfSync',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell6'
        )}`,
    },
    {
        id: 'status',
        label: `${i18n.t(
            'aircraft.aircraftConfig.deploymenthistory.table.headerCell7'
        )}`,
    },
];
