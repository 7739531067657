export const snakeCaseToCamelCase = (str: string): string => {
    if (str.search('_') === -1) {
        return str;
    }
    return str
        .toLowerCase()
        .replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace('-', '').replace('_', '')
        );
};

export const displaySize = (size: string | null): string | null => {
    return size ? `${size} KB` : size;
};
