import React, { useContext, useEffect } from 'react';
import LoadingSpinner from '../../../loaders/LoginSpinner';
import { LightAircraft } from '../../types';
import styles from '../AircraftConfiguration.module.css';

import DeploymentsTable from './DeploymentsTable';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../../../redux/app/features/credentialsSlice';
import { useSelector } from 'react-redux';
import { getDeliveryBundlesHistory } from '../../../../services/api/DeploymentHistory';
import { AircraftContext } from '../../AircraftContext';

type AircraftDeploymentHistoryProps = {
    isFirstLoading: boolean;
    aircraftData: LightAircraft | undefined;
    aircraftIsLoading: boolean;
};

const AircraftDeploymentHistory = ({
    isFirstLoading,
    aircraftData,
    aircraftIsLoading,
}: AircraftDeploymentHistoryProps) => {
    const { selectedAircraftId } = useContext(AircraftContext);
    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const {
        data: bundleDataHistory,
        isLoading: bundleIsLoadingHistory,
        refetch: refetchBundleHistory,
    } = getDeliveryBundlesHistory(selectedAircraftId, token, tokenExpiryDate);

    useEffect(() => {
        refetchBundleHistory();
    }, [selectedAircraftId]);

    if (isFirstLoading) {
        return <LoadingSpinner fullPage={false} putLoginLabel={false} />;
    }
    return (
        <div className={styles.history_deployments_table}>
            {!aircraftData && aircraftIsLoading && bundleIsLoadingHistory ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : (
                <div className={styles.page}>
                    <div className={styles.page_container_history_table}>
                        <DeploymentsTable apps={bundleDataHistory} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default AircraftDeploymentHistory;
