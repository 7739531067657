import { configureStore } from '@reduxjs/toolkit';
import credentialsReducer from './features/credentialsSlice';
import userReducer from './features/userSlice';

export const store = configureStore({
    reducer: {
        credentials: credentialsReducer,
        user: userReducer,
    },
    middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
