import React, { useState, useContext } from 'react';
import { Select } from '@airbus/components-react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import { AircraftContext } from '../AircraftContext';
interface AircraftSelectProps {
    options: any;
}

export const AircraftSelect = ({ options }: AircraftSelectProps) => {
    const { selectedAircraftId, setAircraft } = useContext(AircraftContext);
    const [defaultValue, setDefaultValue] = useState<number>(options[0].id);

    useDeepCompareEffectNoCheck(() => {
        if (selectedAircraftId === options[0].id && options?.length > 0) {
            setDefaultValue(options[0].id);
        } else setDefaultValue(selectedAircraftId || options[0].id);
    }, [selectedAircraftId, options]);

    return (
        <Select
            placeholder="Choose an option"
            className="role-select-component"
            onChange={(event: any) => {
                setAircraft(event.target.value);
            }}
            value={defaultValue}
        >
            {options?.map((option: { id: number; tailId: string }) => (
                <option key={option.id} value={option.id}>
                    {option.tailId}
                </option>
            ))}
        </Select>
    );
};
