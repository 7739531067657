import React, { useEffect, useState } from 'react';
import { ColorModeProvider, Typography } from '@airbus/components-react';
import { Box, Tab, Tabs } from '@mui/material';
import i18n from 'i18next';
import { useHistory, Link } from 'react-router-dom';

import AircraftView from './AircraftView';
import Fleet from './Fleet';
import LoadingSpinner from '../loaders/LoginSpinner';

import classes from './Aircraft.module.css';

const Aircraft = () => {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<
        'aircraft' | 'fleet' | undefined
    >();

    useEffect(() => {
        switch (window.location.pathname) {
            case '/aircraft/fleet-view':
                history.push({
                    pathname: '/aircraft/fleet-view',
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('fleet');
                break;
            case '/aircraft':
            case '/aircraft/aircraft-view':
            default:
                history.push({
                    pathname: '/aircraft/aircraft-view',
                    state: { roleId: null, roleName: null },
                });
                setSelectedTab('aircraft');
                break;
        }
    }, []);

    return (
        <>
            {selectedTab === undefined ? (
                <LoadingSpinner fullPage={false} putLoginLabel={false} />
            ) : (
                <div className={classes.aircraft_container}>
                    <ColorModeProvider mode="dark">
                        <div className="bg-primaryblue-90 main-page-title">
                            <div className="text-left">
                                <Typography variant="h2">
                                    {i18n.t('aircraft.title')}
                                </Typography>
                            </div>
                        </div>
                    </ColorModeProvider>
                    <div className={classes.aircraft_content_container}>
                        <Box sx={{ flexGrow: 1, flex: '0 0' }}>
                            <Tabs
                                sx={{
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    textAlign: 'left',
                                    width: '200px',
                                }}
                                orientation="vertical"
                                value={selectedTab}
                                onChange={(event, value) => {
                                    history.push({
                                        pathname: value,
                                        state: {
                                            roleId: null,
                                            roleName: null,
                                        },
                                    });
                                    setSelectedTab(value);
                                }}
                            >
                                <Tab
                                    label={`${i18n.t('aircraft.aircraft_tab')}`}
                                    component={Link}
                                    to={`/aircraft/aircraft-view`}
                                    value={'aircraft'}
                                />
                                {/* <Tab
                                    label={`${i18n.t('aircraft.fleet_tab')}`}
                                    component={Link}
                                    to={`/aircraft/fleet-view`}
                                    value={'fleet'}
                                /> */}
                            </Tabs>
                        </Box>
                        {selectedTab === 'aircraft' ? (
                            <AircraftView />
                        ) : (
                            <Fleet />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Aircraft;
