import React, { useState, useContext } from 'react';
import i18n from 'i18next';
import {
    Button as AirbusButton,
    ColorModeProvider,
    IconButton,
} from '@airbus/components-react';
import { CheckCircle, Close } from '@airbus/icons/react';
import { Drawer, DialogActions, DialogTitle } from '@mui/material';

import { AppContext } from '../../../AppContext';
import { RoleContext } from './RoleContext';
import { useCustomMutation } from '../utils';
import { displayErrorMessage, Error200 } from '../../../utils/ErrorUtils';
import { IApiError } from '../../../models/AppModels';
import RoleForm from './RoleForm';

import '../side-nav/SideNav.css';
import Role from '../../../models/RoleModel';

type Props = {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
    onClose: () => void;
    onSuccess: () => void;
};

const RoleCreation = ({
    token,
    tokenExpiryDate,
    onClose,
    onSuccess,
}: Props) => {
    const { setRole, refetch } = useContext(RoleContext);
    const { setMessageBanner } = useContext(AppContext);

    const [open] = useState(true);
    const [label, setLabel] = useState<string>();

    const { mutate: addNewRole } = useCustomMutation<Role, Error200>(
        token,
        tokenExpiryDate,
        'role',
        { label },
        'post',
        async (elt) => {
            await refetch();
            await setRole(elt.data.id);
            onClose();
            onSuccess();
            setMessageBanner({
                isBanner: true,
                message: i18n.t('admin.roles.success.create'),
                type: 'success',
            });
        },
        (err) => {
            onClose();
            setMessageBanner({
                isBanner: true,
                message: displayErrorMessage(err as IApiError),
                type: 'error',
            });
        }
    );

    return (
        <div>
            <Drawer
                anchor={'right'}
                onClose={onClose}
                open={open}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 1 / 4,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    <div style={{ display: 'inline-block', width: '100%' }}>
                        <div style={{ float: 'left' }}>
                            {i18n.t('admin.roles.title')}
                        </div>
                        <div style={{ float: 'right' }} onClick={onClose}>
                            <IconButton size="small" variant="ghost">
                                <Close onClick={onClose} />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <RoleForm
                    onChangeLabel={(lab: string) => setLabel(lab)}
                    label={label}
                    inSidePanel={true}
                />
                <ColorModeProvider mode="dark">
                    <div className="dialog-footer">
                        <DialogActions>
                            <AirbusButton
                                disabled={!label}
                                className="button"
                                type="submit"
                                icon={<CheckCircle />}
                                variant="success"
                                onClick={() => addNewRole()}
                            >
                                {i18n.t('admin.roles.dialog.create-button')}
                            </AirbusButton>
                        </DialogActions>
                    </div>
                </ColorModeProvider>
            </Drawer>
        </div>
    );
};

export default RoleCreation;
