import React from 'react';
import { Modal, Inline, Button } from '@airbus/components-react';

import classes from './CancellationModal.module.css';

export type CancellationModalType = {
    openModal: boolean;
    handleClose: () => void;
    popUpDeploymentBlockingType: string;
    popUpMessage: string;
};

type CancellationProps = {
    modalProps: CancellationModalType;
    cancelPlannedDeliveryBundles: () => void;
};
const CancellationModal = ({
    modalProps,
    cancelPlannedDeliveryBundles,
}: CancellationProps) => {
    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={modalProps.openModal}
            onClose={modalProps.handleClose}
        >
            <div tabIndex={-1} className={classes.container}>
                <h5>
                    Warning
                    <span className={classes.float_right}></span>
                </h5>
                <p>{modalProps.popUpMessage}</p>
                {modalProps.popUpDeploymentBlockingType === 'planned' && (
                    <Inline align="end" spacing="2-x">
                        <Button
                            variant="primary"
                            onClick={modalProps.handleClose}
                        >
                            &nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;
                        </Button>

                        <Button onClick={() => cancelPlannedDeliveryBundles()}>
                            Proceed
                        </Button>
                    </Inline>
                )}
                {modalProps.popUpDeploymentBlockingType === 'ongoing' && (
                    <Inline align="end" spacing="2-x">
                        <Button
                            variant="primary"
                            onClick={modalProps.handleClose}
                        >
                            OK
                        </Button>
                    </Inline>
                )}
            </div>
        </Modal>
    );
};

export default CancellationModal;
