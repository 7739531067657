import React from 'react';
import { useEffect, useState } from 'react';
import { Button as AirbusButton } from '@airbus/components-react';
import { Chevronleftdouble } from '@airbus/icons/react';

import './Footer.css';

const Footer = () => {
    const [hideTopButton, setHideTopButton] = useState<boolean>(false);

    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset === 0) setHideTopButton(false);
            else {
                setHideTopButton(true);
            }
        };
    });

    return (
        <div>
            {hideTopButton && (
                <div className="back-to-top">
                    <AirbusButton
                        variant="primary"
                        onClick={() => window.scrollTo(0, 0)}
                        icon={<Chevronleftdouble className="footer-icon" />}
                    ></AirbusButton>
                </div>
            )}
        </div>
    );
};

export default Footer;
