import axios, { AxiosPromise } from 'axios';

import { apiUrl } from '../../utils/ApplicationUtils';

export function getSwagger(apiToken: string | undefined): AxiosPromise<any> {
    const url = apiUrl + '/swagger.json';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
    };

    return axios.get(url, headers);
}
