import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { PostAdd } from '@airbus/icons/react';
import {
    Typography,
    SearchInput,
    Chip,
    Inline,
    Spinner,
    ColorModeProvider,
} from '@airbus/components-react';
import { Box, Tabs, Tab } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import {
    ApplicationValidatorAPIMessage,
    ApplicationValidationDisplay,
} from '../../models/ApplicationValidation';
import TableSimple from '../Tables/TableSimple';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../redux/app/features/credentialsSlice';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';
import { IApiError } from '../../models/AppModels';
import { handleDates } from '../../utils/DateUtils';
import { APPLICATION_VALIDATION } from '../../constants/Validation';
import { AppContext } from '../../AppContext';

import BellIcon from '../../images/bell.png';
import classes from './Listing.module.css';
import { parseName } from '../../utils/Validations';
type TableHeader = {
    label: string;
    link: keyof ApplicationValidationDisplay;
}[];

const headersCommon: TableHeader = [
    { label: 'Title', link: 'title' },
    { label: 'Version', link: 'version' },
    { label: 'Provider', link: 'provider' },
    { label: 'Airline', link: 'airline' },
];

const headersPending: TableHeader = [
    { label: 'Published since', link: 'publishedSince' },
    { label: 'Progress', link: 'progress' },
    { label: 'isRequired', link: 'isActionRequired' },
];

const headersValidated: TableHeader = [
    { label: 'Validated on', link: 'validatedOn' },
    { label: 'Progress', link: 'progress' },
];

const headersRejected: TableHeader = [
    { label: 'Rejected on', link: 'rejectedOn' },
    { label: 'By', link: 'by' },
];

const headersScannedFailed: TableHeader = [
    { label: 'Published since', link: 'publishedSince' },
    { label: 'Scan failed Since', link: 'scanFailedSince' },
];

const toAppsPendingDisplay = (
    apps: ApplicationValidatorAPIMessage[],
    selectedTab: string
): ApplicationValidationDisplay[] => {
    return apps
        .map((app): ApplicationValidationDisplay | undefined => {
            const common = {
                title: app.name,
                version: app.version,
                provider: app.provider,
                airline: app.airline,
                appId: app.id,
                appVersionId: app.appVersionId,
            };

            if (
                selectedTab === 'pending' &&
                app.validation_state === 'pending'
            ) {
                let publishedSince = '';

                if (app.created_at) {
                    const oneDay = 24 * 60 * 60 * 1000;
                    const diffDays = Math.round(
                        Math.abs(
                            (app.created_at.getTime() - new Date().getTime()) /
                                oneDay
                        )
                    );
                    publishedSince = `${diffDays} ${i18n.t(
                        'validation.list.days'
                    )}`;
                }

                return {
                    ...common,
                    publishedSince,
                    progress: `${app.currentValidation}/${app.validationNeeded}`,
                    isActionRequired:
                        app.validations.filter((v) => !!v).length < 2 &&
                        !app.isDisabled ? (
                            <Inline spacing="2-x">
                                <Chip
                                    label={`${i18n.t(
                                        'validation.list.action_required'
                                    )}`}
                                    icon={
                                        <img
                                            src={BellIcon}
                                            alt="bell"
                                            className={classes.iconHeight}
                                        />
                                    }
                                    variant="info"
                                />
                            </Inline>
                        ) : (
                            <></>
                        ),
                    isActionRequiredBoolean:
                        app.validations[0] === null ||
                        (app.validations[0] !== null && !app.isDisabled),
                };
            } else if (
                selectedTab === APPLICATION_VALIDATION.REJECTED &&
                app.validation_state === APPLICATION_VALIDATION.REJECTED
            ) {
                let by = '';
                let rejectedOn = '';

                const randomRejected = app.validations.find(
                    ({ validation_state }) =>
                        validation_state === APPLICATION_VALIDATION.REJECTED
                );

                if (app.validation_state_at) {
                    rejectedOn = moment(app.validation_state_at).format(
                        'D MMM YYYY'
                    );
                }
                if (randomRejected?.user) {
                    by = parseName(randomRejected.user.name);
                }

                return {
                    ...common,
                    rejectedOn,
                    by,
                };
            } else if (
                selectedTab === APPLICATION_VALIDATION.VALIDATED &&
                app.validation_state === APPLICATION_VALIDATION.VALIDATED
            ) {
                let validatedOn = '';

                if (app.validation_state_at) {
                    validatedOn = moment(app.validation_state_at).format(
                        'D MMM YYYY'
                    );
                }

                return {
                    ...common,
                    validatedOn,
                    progress: `${app.currentValidation}/${app.validationNeeded}`,
                };
            } else if (
                selectedTab === APPLICATION_VALIDATION.SCAN_FAILED &&
                app.validation_state === APPLICATION_VALIDATION.SCAN_FAILED
            ) {
                let publishedSince = '';
                let scanFailedSince = '';

                if (app.created_at) {
                    const oneDay = 24 * 60 * 60 * 1000;
                    const diffDays = Math.round(
                        Math.abs(
                            (app.created_at.getTime() - new Date().getTime()) /
                                oneDay
                        )
                    );
                    publishedSince = `${diffDays} ${i18n.t(
                        'validation.list.days'
                    )}`;
                }
                if (app.scan_state_at) {
                    const oneDay = 24 * 60 * 60 * 1000;
                    const diffDays = Math.round(
                        Math.abs(
                            (app.scan_state_at.getTime() -
                                new Date().getTime()) /
                                oneDay
                        )
                    );
                    scanFailedSince = `${diffDays} ${i18n.t(
                        'validation.list.days'
                    )}`;
                }
                return {
                    ...common,
                    publishedSince,
                    scanFailedSince,
                };
            }
            return undefined;
        })
        .filter((app) => app) as ApplicationValidationDisplay[];
};

const acceptedFilters: string[] = [
    APPLICATION_VALIDATION.PENDING,
    APPLICATION_VALIDATION.VALIDATED,
    APPLICATION_VALIDATION.REJECTED,
];

const Listing = () => {
    const { setMessageBanner } = useContext(AppContext);

    const [selectedTab, setSelectedTab] = useState<
        'pending' | 'validated' | 'rejected' | 'scan_failed'
    >(APPLICATION_VALIDATION.PENDING);
    const [search, setSearch] = useState<string>('');

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const history = useHistory();

    const getApps = () => {
        const options = {
            headers: {
                Authorization: 'Bearer ' + (token ?? ''),
                'Content-Type': 'application/json;charset=UTF-8',
            },
        };

        return useQuery<ApplicationValidatorAPIMessage[]>(
            ['get', 'app-validator'],
            () =>
                axios
                    .get(`${apiUrl}/app-validator`, options)
                    .then(({ data }) => {
                        if (data.error) {
                            setMessageBanner({
                                isBanner: true,
                                message: `${data.error.message}`,
                                type: 'error',
                            });
                        } else {
                            const withDate = data.map(handleDates);
                            return withDate;
                        }
                    }),
            {
                enabled: !isTokenExpired(tokenExpiryDate ?? 0),
                refetchOnWindowFocus: false,
                retry: false,
                onError: (err) => {
                    setMessageBanner({
                        isBanner: true,
                        message: displayErrorMessage(err as IApiError),
                        type: 'error',
                    });
                },
            }
        );
    };
    let headers = [...headersCommon];
    if (selectedTab === APPLICATION_VALIDATION.PENDING) {
        headers = [...headersCommon, ...headersPending];
    } else if (selectedTab === APPLICATION_VALIDATION.REJECTED) {
        headers = [...headersCommon, ...headersRejected];
    } else if (selectedTab === APPLICATION_VALIDATION.VALIDATED) {
        headers = [...headersCommon, ...headersValidated];
    } else if (selectedTab === APPLICATION_VALIDATION.SCAN_FAILED) {
        headers = [...headersCommon, ...headersScannedFailed];
    }

    const { isLoading, data } = getApps();
    const applications: ApplicationValidatorAPIMessage[] =
        Array.isArray(data) && data.length ? data.map(handleDates) : [];

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const filter = queryParams.get('filter');
        if (
            filter &&
            (filter === APPLICATION_VALIDATION.PENDING ||
                filter === APPLICATION_VALIDATION.REJECTED ||
                filter === APPLICATION_VALIDATION.VALIDATED ||
                filter === APPLICATION_VALIDATION.SCAN_FAILED) &&
            acceptedFilters.includes(filter)
        ) {
            setSelectedTab(filter);
        }
    }, []);

    const onChange = (
        event: SyntheticEvent<any, Event>,
        value: string
    ): void => {
        setSearch(value);
    };

    const searchedApp =
        search !== ''
            ? applications.filter((app) => app.name.startsWith(search))
            : applications;

    return (
        <div className="center">
            <ColorModeProvider mode="dark">
                <div className="bg-primaryblue-90 main-page-title">
                    <div className="text-left">
                        <Typography variant="h2">
                            {i18n.t('validation.title')}
                        </Typography>
                    </div>
                </div>
            </ColorModeProvider>
            {isLoading && <Spinner className={classes.spinner} />}
            {!isLoading && applications.length > 0 && (
                <div className="admin-content-container">
                    <Box sx={{ flexGrow: 1, flex: '0 0' }}>
                        <Tabs
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                textAlign: 'left',
                                width: '200px',
                            }}
                            orientation="vertical"
                            value={selectedTab}
                            onChange={(event, value) => {
                                value === APPLICATION_VALIDATION.PENDING &&
                                    history.push({
                                        pathname:
                                            APPLICATION_VALIDATION.PENDING,
                                        state: {
                                            roleId: null,
                                            roleName: null,
                                        },
                                    });
                                setSelectedTab(value);
                            }}
                        >
                            <Tab
                                label={`${i18n.t('validation.tab.pending')} (${
                                    searchedApp.filter(
                                        (app) =>
                                            app.validation_state ===
                                            APPLICATION_VALIDATION.PENDING
                                    ).length
                                })`}
                                component={Link}
                                to={`/validation-center?filter=pending`}
                                value={APPLICATION_VALIDATION.PENDING}
                            />
                            <Tab
                                label={`${i18n.t(
                                    'validation.tab.validated'
                                )} (${
                                    searchedApp.filter(
                                        (app) =>
                                            app.validation_state ===
                                            APPLICATION_VALIDATION.VALIDATED
                                    ).length
                                })`}
                                component={Link}
                                to={`/validation-center?filter=validated`}
                                value={APPLICATION_VALIDATION.VALIDATED}
                            />
                            <Tab
                                label={`${i18n.t('validation.tab.rejected')} (${
                                    searchedApp.filter(
                                        (app) =>
                                            app.validation_state ===
                                            APPLICATION_VALIDATION.REJECTED
                                    ).length
                                })`}
                                component={Link}
                                to={`/validation-center?filter=rejected`}
                                value={APPLICATION_VALIDATION.REJECTED}
                            />
                            <Tab
                                label={`${i18n.t(
                                    'validation.tab.scanFailed'
                                )} (${
                                    searchedApp.filter(
                                        (app) =>
                                            app.validation_state ===
                                            APPLICATION_VALIDATION.SCAN_FAILED
                                    ).length
                                })`}
                                component={Link}
                                to={`/validation-center?filter=scan_failed`}
                                value={APPLICATION_VALIDATION.SCAN_FAILED}
                            />
                        </Tabs>
                    </Box>
                    {search ||
                    toAppsPendingDisplay(searchedApp, selectedTab).length ? (
                        <div className="apps-table">
                            <div className={classes.page_sub_container}>
                                <SearchInput
                                    placeholder="Search"
                                    onChange={onChange}
                                />
                            </div>

                            <TableSimple<ApplicationValidationDisplay>
                                headers={headers}
                                columns={toAppsPendingDisplay(
                                    searchedApp,
                                    selectedTab
                                )}
                                defaultOrderBy={'title'}
                                selectedTab={selectedTab}
                                uniqAppVersionIdentifier="appVersionId"
                                onRowClick={(
                                    link: string | number | undefined
                                ) => {
                                    if (
                                        selectedTab !==
                                        APPLICATION_VALIDATION.SCAN_FAILED
                                    ) {
                                        if (!link) {
                                            return;
                                        }
                                        history.push({
                                            pathname: `/validation-center/${link}`,
                                            state: {
                                                validationTab: selectedTab,
                                            },
                                        });
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div className={classes.emptyList}>
                            Empty list - No{' '}
                            {selectedTab === APPLICATION_VALIDATION.SCAN_FAILED
                                ? 'scan failed'
                                : selectedTab}{' '}
                            applications
                        </div>
                    )}
                </div>
            )}
            {!isLoading && (!applications || !applications.length) && (
                <div className="content-applications">
                    <PostAdd />
                    <Typography variant="large" id="title">
                        {i18n.t('validation.list.noApps.title')}
                    </Typography>
                    <Typography variant="small" id="content">
                        {i18n.t('validation.list.noApps.content')}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default Listing;
