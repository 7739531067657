import React, { ChangeEvent, useState } from 'react';
import { Modal, Button, Inline, Textarea } from '@airbus/components-react';
import { CancelOutline, Close } from '@airbus/icons/react';
import { CustomLoadingButton } from '../../shared/components/CustomLoadingButton/CustomLoadingButton';

import '../../i18n/config';
import classes from './RejectBox.module.css';

const RejectBox = ({
    disabled,
    onReject,
}: {
    disabled: boolean;
    onReject: (comment: string) => void;
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [rejectionComment, setComment] = useState('');
    const [enabledClick, setEnabledClick] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className={classes.reject_button}>
            <Button
                type="submit"
                icon={<CancelOutline />}
                variant="error"
                disabled={disabled}
                onClick={() => {
                    handleOpen();
                }}
            >
                Reject
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                open={open}
                onClose={handleClose}
            >
                <div tabIndex={-1} className={classes.container}>
                    <h5>
                        Warning
                        <span className={classes.float_right}>
                            <Close onClick={() => setOpen(false)} />
                        </span>
                    </h5>
                    <p>You are about to reject the application.</p>
                    <Textarea
                        style={{ marginBottom: '2em' }}
                        rows={4}
                        placeholder="Please indicate the reasons for your rejection"
                        onChange={(
                            event: ChangeEvent<HTMLTextAreaElement>,
                            value: string
                        ) => setComment(value)}
                    />
                    <Inline align="end" spacing="2-x">
                        <Button variant="primary" onClick={handleClose}>
                            &nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;
                        </Button>

                        <CustomLoadingButton
                            variant="error"
                            onClick={async () => {
                                setEnabledClick(false);
                                await onReject(rejectionComment);
                                setEnabledClick(true);
                            }}
                            enabledClick={enabledClick}
                            startIcon={<CancelOutline />}
                            disabled={!rejectionComment.trim().length}
                            label={'Confirm Rejection'}
                        />
                    </Inline>
                </div>
            </Modal>
        </div>
    );
};
export default RejectBox;
