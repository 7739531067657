import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from '../../AppContext';
import { CurrentConfig } from '../../models/CurrentConfig';
import { IApiError } from '../../models/AppModels';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';
import { handleDates } from '../../utils/DateUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';

export const getCurrentConfig = (
    aircraftId: number | undefined,
    token: any,
    tokenExpiryDate: number | undefined
) => {
    const { setMessageBanner } = useContext(AppContext);
    const options = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json',
        },
        params: aircraftId,
    };

    return useQuery<CurrentConfig[]>(
        ['get', 'current-apps-config'],
        () =>
            axios
                .get(`${apiUrl}/current-apps-config/${aircraftId}`, options)
                .then(({ data }) => {
                    if (data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${data.error.message}`,
                            type: 'error',
                        });
                    } else {
                        if (data.data) {
                            const currentConfig = data.data.map(handleDates);
                            return currentConfig;
                        }
                    }
                }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0),
            refetchOnWindowFocus: false,
            retry: false,
            onError: (err) => {
                setMessageBanner({
                    isBanner: true,
                    message: displayErrorMessage(err as IApiError),
                    type: 'error',
                });
            },
        }
    );
};
