import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import classes from './CustomLoadingButton.module.css';

type Props = {
    variant: 'error' | 'primary' | 'success';
    onClick: React.MouseEventHandler<any>;
    enabledClick: boolean;
    startIcon: React.ReactElement;
    disabled: boolean;
    label: string;
};

export const CustomLoadingButton = ({
    variant,
    onClick,
    enabledClick,
    startIcon,
    disabled,
    label,
}: Props) => {
    return (
        <LoadingButton
            className={`${classes.loading_button} ${classes[variant]}`}
            color={variant}
            onClick={onClick}
            loading={enabledClick}
            loadingPosition="start"
            startIcon={startIcon}
            variant="contained"
            disabled={disabled}
        >
            {label}
        </LoadingButton>
    );
};
