export const APPLICATION_VALIDATION: IApplicationValidation = {
    REJECTED: 'rejected',
    VALIDATED: 'validated',
    PENDING: 'pending',
    SCAN_FAILED: 'scan_failed',
};

export const APPLICATION_DETAILS_VALIDATION: IApplicationDetailsValidation = {
    REJECTED: 'rejected',
    VALIDATED: 'validated',
};

interface IApplicationValidation {
    REJECTED: 'rejected';
    VALIDATED: 'validated';
    PENDING: 'pending';
    SCAN_FAILED: 'scan_failed';
}

interface IApplicationDetailsValidation {
    REJECTED: 'rejected';
    VALIDATED: 'validated';
}
