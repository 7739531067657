import {
    Card,
    CardContent,
    Inline,
    SearchInput,
    Tooltip,
} from '@airbus/components-react';

import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PlaneIcon from '../../images/AirbusA320.png';
// import SettingIcon from '../icons/SettingIcon';
import LoadingSpinner from '../loaders/LoginSpinner';
import styles from './AircraftView.module.css';
import { LightAircraft, AircraftFilters } from './types';
import { getAircrafts } from './utils';

const AircraftView = () => {
    // const buttonFilterRef = useRef(null);

    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<AircraftFilters>({});
    const [selectedAircraftID, setSelectedAircraftID] = useState<number>();
    const history = useHistory();

    const onCardClick = (acId: number) => {
        setSelectedAircraftID(acId);
    };

    useEffect(() => {
        history.push({
            pathname: `/aircraft/aircraft-configuration/overview/${selectedAircraftID}`,
            state: { roleId: null, roleName: null },
        });
    }, [selectedAircraftID]);

    useEffect(() => {
        switch (window.location.pathname) {
            case '/aircraft':
                history.push({
                    pathname: '/aircraft/aircraft-view',
                    state: { roleId: null, roleName: null },
                });

                break;
            case '/aircraft/aircraft-view':
                history.push({
                    pathname: '/aircraft/aircraft-view',
                    state: { roleId: null, roleName: null },
                });

                break;
            default:
                history.push({
                    pathname: '/aircraft/aircraft-view',
                    state: { roleId: null, roleName: null },
                });

                break;
        }
    }, []);

    const { data: aircraftData, isLoading: aircraftIsLoading } = getAircrafts(
        filters,
        setIsFirstLoading
    );

    if (isFirstLoading) {
        return <LoadingSpinner fullPage={false} putLoginLabel={false} />;
    }

    return (
        <div className={styles.page}>
            <div className={styles.page_container}>
                {!isFirstLoading &&
                aircraftData &&
                aircraftData.length === 0 &&
                filters.search === undefined ? (
                    <div className={styles.emptyList}>
                        No aircraft are associated with your user profile.
                        Please contact your system admin if required
                    </div>
                ) : (
                    <>
                        <div className={styles.search_container}>
                            <div className={styles.search}>
                                <div className={styles.search_input}>
                                    <SearchInput
                                        // disabled={showLoading}
                                        placeholder={i18n.t(
                                            'deployment.schedule.aircrafts_to_deploy_search'
                                        )}
                                        onChange={(evt, newValue) => {
                                            setFilters({
                                                search: newValue,
                                            });
                                        }}
                                    />
                                </div>
                                {
                                    // <IconButton
                                    //     ref={buttonFilterRef}
                                    //     className={styles.filter_button}
                                    //     variant="primary"
                                    //     aria-label="Filters"
                                    //     onClick={() =>
                                    //         console.log('settings off')
                                    //     }
                                    //     aria-haspopup="dialog"
                                    // >
                                    //     <SettingIcon />
                                    // </IconButton>
                                }
                            </div>
                        </div>
                        {aircraftIsLoading ? (
                            <LoadingSpinner
                                fullPage={false}
                                putLoginLabel={false}
                            />
                        ) : (
                            <Inline spacing="2-x" wrap>
                                {(aircraftData as LightAircraft[]).map(
                                    (ac, index) => (
                                        <Card
                                            className={styles.ac_card}
                                            translate
                                            onClick={() => onCardClick(ac.id)}
                                            key={index}
                                        >
                                            <CardContent
                                                className={styles.card_content}
                                            >
                                                <div
                                                    className={
                                                        styles.card_remote_loading
                                                    }
                                                >
                                                    {ac.remoteLoadingTooltipMessage && (
                                                        <div
                                                            className={
                                                                styles.card_remote_loading_tooltip_container
                                                            }
                                                        >
                                                            <Tooltip
                                                                className={
                                                                    styles.card_remote_loading_tooltip
                                                                }
                                                                placement="top"
                                                                label={
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                ac
                                                                                    .remoteLoadingTooltipMessage
                                                                                    .message
                                                                            }
                                                                        </p>
                                                                        {ac
                                                                            .remoteLoadingTooltipMessage
                                                                            .error ?? (
                                                                            <p>
                                                                                {
                                                                                    ac
                                                                                        .remoteLoadingTooltipMessage
                                                                                        .error
                                                                                }
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                }
                                                            >
                                                                {
                                                                    ac.remoteLoadingIcon
                                                                }
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                                <img
                                                    className={
                                                        styles.card_plane
                                                    }
                                                    src={PlaneIcon}
                                                    alt="plane"
                                                />
                                                <div
                                                    className={
                                                        styles.card_tail_id
                                                    }
                                                >
                                                    {ac.tailId}
                                                </div>
                                                <div
                                                    className={
                                                        styles.card_company
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles.card_company_text
                                                        }
                                                    >
                                                        {ac.company}
                                                    </p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    )
                                )}
                            </Inline>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AircraftView;
