import axios, { AxiosPromise } from 'axios';
import { apiUrl } from '../../utils/ApplicationUtils';

export function updateAppVersionStatus(
    apiToken: string | undefined,
    applicationVersionId: number,
    status: string,
    comment?: string
): AxiosPromise<any> {
    const url = apiUrl + '/app-validator';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json',
        },
    };

    const payload = {
        validationState: status,
        applicationVersionId,
        comment,
    };

    return axios.post(url, payload, headers);
}
