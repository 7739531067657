import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FileCopy, Lock } from '@airbus/icons/react';
import {
    MenuButton,
    Menu,
    ColorModeProvider,
    IconButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Input,
} from '@airbus/components-react';

import log from '../../logger';
import { selectToken } from '../../redux/app/features/credentialsSlice';
import { envStage } from '../../utils/ApplicationUtils';
import { EnvStage } from '../../constants/EnvStage';

type Props = {
    artifactoryToken: string;
};

const TokenMenu = ({ artifactoryToken }: Props) => {
    const token = useSelector(selectToken);

    useEffect(() => {
        log.debug('[ARTIFACTORY_TOKEN-API] - Starts call');
    });

    return (
        <div>
            <Menu>
                <MenuButton variant="ghost">
                    <Lock />
                </MenuButton>
                <ColorModeProvider mode="dark">
                    <MenuList>
                        <MenuItem className="menu-item">
                            SDK token
                            <Input
                                value={token}
                                className="white-input"
                                placeholder="Placeholder"
                                endAdornment={
                                    <IconButton
                                        value={token}
                                        className="dark-input-icon"
                                        variant="ghost"
                                        size="small"
                                    >
                                        <FileCopy
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    token ?? ''
                                                );
                                            }}
                                        />
                                    </IconButton>
                                }
                            />
                        </MenuItem>
                        {envStage === EnvStage.Int && (
                            <>
                                <MenuDivider />
                                <MenuItem className="menu-item">
                                    Artifactory token
                                    <Input
                                        value={artifactoryToken}
                                        className="white-input"
                                        placeholder="Placeholder"
                                        endAdornment={
                                            <IconButton
                                                value={artifactoryToken}
                                                className="dark-input-icon"
                                                variant="ghost"
                                                size="small"
                                            >
                                                <FileCopy
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            artifactoryToken
                                                        );
                                                    }}
                                                />
                                            </IconButton>
                                        }
                                    />
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </ColorModeProvider>
            </Menu>
        </div>
    );
};

export default TokenMenu;
