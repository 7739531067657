import React, { useEffect, useRef } from 'react';
import { DataTable, SearchInput, Typography } from '@airbus/components-react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import i18n from 'i18next';
import { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { Column, UseSortByColumnOptions } from 'react-table';

import { IApiError } from '../../../models/AppModels';
import { ApiCallbacks, useApiCall } from '../utils';
import * as Utils from '../../../utils/ErrorUtils';
import { getWindowDimensions } from '../../../utils/DomUtils';
import { AppContext } from '../../../AppContext';
import { DataTableQuery } from '../GenericTable';

import './../Table.css';
import classes from './userTable.module.css';
import { User, UserDataTable } from '../../../models/UserModel';

import { userToDataTable } from '../../../utils/models/user.util';
import { useLocation } from 'react-router-dom';
import { UserRoleUpdate } from './UserRoleUpdate';
import MultipleChips from './MultipleChips';

const defaultSort = 'name';

type Props = {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
};

export const UserTable = ({ token, tokenExpiryDate }: Props) => {
    const location: any = useLocation();

    // Error handling
    const { setMessageBanner } = useContext(AppContext);

    const columns: (Column<UserDataTable> &
        UseSortByColumnOptions<UserDataTable>)[] = [
        {
            Header: `${i18n.t('admin.users.table.headerCell1')}`,
            accessor: 'name',
            width: 100,
        },
        {
            Header: `${i18n.t('admin.users.table.headerCell2')}`,
            accessor: 'email',
            width: 150,
        },
        {
            Header: `${i18n.t('admin.users.table.headerCell3')}`,
            accessor: 'company',
            width: 100,
        },
    ];

    // DataTable
    /* DataTable props managed externally : data, loading, pageCount, rowCount */
    const [userData, setUserData] = useState<UserDataTable[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [openSideNav, setOpenSideNav] = useState<boolean>(false);
    const [sideNavDataUpdated, setSideNavDataUpdated] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<
        UserDataTable | undefined
    >();
    const [roleId, setFilterRole] = useState<string | undefined | null>(
        undefined
    );

    !roleId &&
        columns.push({
            Header: `${i18n.t('admin.users.table.headerCell4')}`,
            accessor: (row) => row.roles,
            Cell: ({ value }: { value: string[] }) => (
                <MultipleChips strings={value}></MultipleChips>
            ),
            disableSortBy: true,
            width: getWindowDimensions().width < 2000 ? 400 : 200,
        });

    const apiCallCallbacks: ApiCallbacks<User> = {
        onSuccess: (userData: User[], total: number) => {
            setUserData(userData.map(userToDataTable));
            if (total !== rowCount) {
                setRowCount(total);
            }
            const newPageCount = Math.ceil(total / query.pageSize);
            if (newPageCount !== pageCount) {
                setPageCount(newPageCount);
            }
        },
        onError: (error: IApiError) => {
            setMessageBanner({
                isBanner: true,
                message: Utils.displayErrorMessage(error),
                type: 'error',
            });
        },
    };

    const onQueryUpdate = useCallback((queryParams: DataTableQuery) => {
        if (queryParams.sortBy.length > 1) {
            queryParams.sortBy.splice(0, queryParams.sortBy.length - 1);
        }

        setQuery(queryParams);
    }, []);

    // Search button
    const updateTable = useRef(false);

    const onChange = (
        event: SyntheticEvent<any, Event>,
        value: string
    ): void => {
        setSearch(value);
        setQuery({ ...query, pageIndex: 0 });
    };

    const onRowClick = (row: UserDataTable) => {
        if (roleId) {
            return;
        }
        setOpenSideNav(true);
        setSideNavDataUpdated(false);
        setSelectedUser(row);
    };
    const [[query, setQuery], [setSearch], [loading]] = useApiCall(
        token,
        tokenExpiryDate,
        defaultSort,
        'user',
        apiCallCallbacks,
        sideNavDataUpdated,
        { roleId: roleId, noStaticRole: 'true' }
    );

    // Handle data
    useDeepCompareEffect(() => {
        window.history.replaceState({}, document.title);
        setFilterRole((location.state && location.state.roleId) || null);
    }, [query, roleId, location.state]);
    // Handle data with side nav state
    useEffect(() => {
        if (updateTable.current === false) return;
        window.history.replaceState({}, document.title);
        setFilterRole((location.state && location.state.roleId) || null);
        updateTable.current = false;
    }, [sideNavDataUpdated]);

    return (
        <div className={classes.page}>
            <div className={classes.page_container}>
                <div className={classes.page_sub_container}>
                    <div>
                        <SearchInput placeholder="Search" onChange={onChange} />
                    </div>
                </div>
                <br />

                {location.state && location.state.roleName && (
                    <Typography variant="h5">
                        Users with {location.state.roleName} role
                    </Typography>
                )}
                <div className="generic-table">
                    <DataTable
                        columns={columns}
                        data={userData}
                        loading={loading}
                        onQueryUpdate={onQueryUpdate}
                        pageCount={pageCount}
                        rowCount={rowCount}
                        sortBy={query.sortBy}
                        onRowClick={onRowClick}
                    />
                </div>
            </div>
            {openSideNav && (
                <div>
                    <UserRoleUpdate
                        onClose={() => setOpenSideNav(false)}
                        onSuccess={() => {
                            setSideNavDataUpdated(true);
                            updateTable.current = true;
                            setOpenSideNav(false);
                        }}
                        selectedUser={selectedUser}
                        token={token}
                        tokenExpiryDate={tokenExpiryDate}
                    />
                </div>
            )}
        </div>
    );
};
