import React, { useState } from 'react';
import i18n from 'i18next';

import classes from './AircraftFilter.module.css';
import AirlineFilter from './filters/AirlineFilter';
import ModelFilter from './filters/ModelFilter';
import { Button } from '@airbus/components-react';
import { AircraftModel } from '../../../../models/Aircraft';

type NavId = 'airlines' | 'models';

type Nav = {
    id: NavId;
    nav: string;
    title: string;
    getSubtitle: (toAdd: string) => string;
};

const navs: Nav[] = [
    {
        id: 'airlines',
        nav: `${i18n.t(
            'deployment.schedule.aircrafts_to_deploy_filter_nav_airlines_nav'
        )}`,
        title: `${i18n.t(
            'deployment.schedule.aircrafts_to_deploy_filter_nav_airlines_title'
        )}`,
        getSubtitle: (toAdd: string) =>
            `${i18n
                .t(
                    'deployment.schedule.aircrafts_to_deploy_filter_nav_airlines_subtitle'
                )
                .replace('###$$$', toAdd)}`,
    },
    {
        id: 'models',
        nav: `${i18n.t(
            'deployment.schedule.aircrafts_to_deploy_filter_nav_models_nav'
        )}`,
        title: `${i18n.t(
            'deployment.schedule.aircrafts_to_deploy_filter_nav_models_title'
        )}`,
        getSubtitle: (toAdd: string) =>
            `${i18n
                .t(
                    'deployment.schedule.aircrafts_to_deploy_filter_nav_models_subtitle'
                )
                .replace('###$$$', toAdd)}`,
    },
];

type Props = {
    airlines: {
        id: number;
        name: string;
    }[];
    currentChosenAirlines: number[];

    models: AircraftModel[];
    currentChosenModels: AircraftModel[];
    close: () => void;
    apply: (chosenAirlines: number[], chosenModels: AircraftModel[]) => void;
};

const AircraftFilter = ({
    airlines,
    currentChosenAirlines,
    models,
    currentChosenModels,
    close,
    apply,
}: Props) => {
    const [activeNav, setActiveNav] = useState<NavId>('airlines');

    const [chosenAirlines, setChosenAirlines] = useState<number[]>(
        currentChosenAirlines
    );
    const [chosenModels, setChosenModels] =
        useState<AircraftModel[]>(currentChosenModels);

    const selectedNav = navs.find((nav) => nav.id === activeNav) as Nav;
    let toAdd = '';
    switch (selectedNav.id) {
        case 'airlines':
            toAdd = airlines.length.toString();
            break;
        case 'models':
            toAdd = models.length.toString();
            break;
    }
    const subtitle = selectedNav.getSubtitle(toAdd);

    const idToComponent = (id: NavId): JSX.Element => {
        switch (id) {
            case 'airlines':
                return (
                    <AirlineFilter
                        options={airlines.map((a) => ({
                            value: a.id.toString(),
                            label: a.name,
                        }))}
                        currentChosenOptions={chosenAirlines.map((cca) =>
                            cca.toString()
                        )}
                        onFilterChange={(selectedAirlines: number[]) => {
                            setChosenAirlines(selectedAirlines);
                        }}
                    />
                );
            case 'models':
                return (
                    <ModelFilter
                        options={models}
                        currentChosenOptions={chosenModels}
                        onFilterChange={(selectedModels: AircraftModel[]) => {
                            setChosenModels(selectedModels);
                        }}
                    />
                );
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.nav}>
                    {navs.map((nav, index) => (
                        <button
                            key={index}
                            className={`${classes.nav_item} ${
                                activeNav === nav.id ? classes.active : ''
                            }`}
                            onClick={() => setActiveNav(nav.id)}
                        >
                            {nav.nav}
                        </button>
                    ))}
                </div>
                <div className={classes.page}>
                    <div className={classes.page_header}>
                        <p className={classes.page_header_title}>
                            {selectedNav.title}
                        </p>
                        <p
                            className={`${classes.page_header_subtitle} ${
                                subtitle === '-' ? classes.hidden : ''
                            }`}
                        >
                            {subtitle}
                        </p>
                        <div className={classes.line} />
                    </div>
                    <div className={classes.page_body}>
                        {idToComponent(activeNav)}
                    </div>
                </div>
            </div>
            <div className={classes.apply}>
                <Button
                    className={classes.apply_button}
                    variant="primary"
                    onClick={() => apply(chosenAirlines, chosenModels)}
                >
                    {i18n.t(
                        'deployment.schedule.aircrafts_to_deploy_filter_apply'
                    )}
                </Button>
                <Button
                    className={classes.apply_button}
                    variant="secondary"
                    onClick={() => {
                        setChosenAirlines([]);
                        setChosenModels([]);
                    }}
                >
                    {i18n.t(
                        'deployment.schedule.aircrafts_to_deploy_filter_reset'
                    )}
                </Button>
                <Button
                    className={classes.apply_button}
                    variant="secondary"
                    onClick={() => close()}
                >
                    {i18n.t(
                        'deployment.schedule.aircrafts_to_deploy_filter_cancel'
                    )}
                </Button>
            </div>
        </div>
    );
};

export default AircraftFilter;
