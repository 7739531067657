import { createContext } from 'react';

import { IError } from './models/AppModels';

interface IAppContext {
    messageBanner: IError;
    setMessageBanner: (err: IError) => void;
}

export const defaultMessageBanner = {
    isBanner: false,
    message: '',
    type: undefined,
};

const defaultState = {
    messageBanner: defaultMessageBanner,
    setMessageBanner: () => {
        return;
    },
};

export const AppContext = createContext<IAppContext>(defaultState);
