import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableSortLabel } from '@airbus/components-react';
import { visuallyHidden } from '@mui/utils';
import DataRow from './DataRow';
import styles from './DataTable.module.css';
import { DEPLOYMENT_HISTORY_HEAD_CELLS } from '../../../../constants/AircraftDeploymentHistory';
import {
    AppDisplayData,
    CustomSortDirection,
    RowDisplayData,
} from '../../types';
import { AircraftDeliveryBundle } from '../../../../models/AircraftDeliveryBundle';
import { beautifyStatus } from '../../../../utils/DataTableUtils';
type Props = {
    refreshAppList?: () => void;
    apps: AircraftDeliveryBundle[];
};

const DataTable = ({ apps }: Props) => {
    const [order, setOrder] = useState<CustomSortDirection>('asc');
    const [orderBy, setOrderBy] = useState<keyof RowDisplayData>('loading');

    const descendingComparator = (
        a: AppDisplayData,
        b: AppDisplayData,
        orderBy: keyof RowDisplayData
    ) => {
        const paramA = a.mainApp[orderBy];
        const paramB = b.mainApp[orderBy];

        if (!paramA) {
            return 1;
        }
        if (!paramB) {
            return -1;
        }

        if (paramB < paramA) {
            return -1;
        }
        if (paramB > paramA) {
            return 1;
        }
        return 0;
    };

    const getComparator = (
        order: CustomSortDirection,
        orderBy: keyof RowDisplayData
    ) => {
        return order === 'desc'
            ? (a: AppDisplayData, b: AppDisplayData) =>
                  descendingComparator(a, b, orderBy)
            : (a: AppDisplayData, b: AppDisplayData) =>
                  -descendingComparator(a, b, orderBy);
    };

    const appDisplayInfos = apps.length
        ? apps.map((app: AircraftDeliveryBundle) => {
              return {
                  mainApp: {
                      id: app.id,
                      loading: app.loading,
                      upload: app.upload,
                      startDate: app.startDate,
                      endtDate: app.endtDate,
                      applications: app.applications,
                      sizeOfSync: app.sizeOfSync,
                      status: beautifyStatus(app.status),
                  },
                  appVersion: app.app_version.map((appVersion) => ({
                      id: appVersion.id,
                      name: appVersion.name,
                      component: appVersion.component.map((component) => ({
                          id: component.id,
                          name: component.name,
                          status: component.status,
                      })),
                  })),
              };
          })
        : [];

    const handleRequestSort = (property: keyof RowDisplayData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div>
            {appDisplayInfos?.length && (
                <TableContainer
                    className={styles.table_container}
                    component={Paper}
                >
                    <Box sx={{ overflow: 'auto' }}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow
                                    className={styles.table_header}
                                    sx={{
                                        '& > *': { borderBottom: 'unset' },
                                        bgcolor: '#fafbfc',
                                    }}
                                >
                                    {DEPLOYMENT_HISTORY_HEAD_CELLS.map(
                                        (headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                sortDirection={
                                                    orderBy === headCell.id
                                                        ? order
                                                        : false
                                                }
                                                sx={{ bgColor: '#fafbfc' }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === headCell.id
                                                    }
                                                    direction={
                                                        orderBy === headCell.id
                                                            ? order
                                                            : 'asc'
                                                    }
                                                    onClick={() => {
                                                        handleRequestSort(
                                                            headCell.id
                                                        );
                                                    }}
                                                >
                                                    {headCell.label}
                                                    {orderBy ===
                                                        headCell.id ?? (
                                                        <Box
                                                            component="span"
                                                            sx={visuallyHidden}
                                                        >
                                                            {order === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </Box>
                                                    )}
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    )}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ bgColor: '#fafbfc' }}>
                                {appDisplayInfos?.length &&
                                    appDisplayInfos
                                        .slice()
                                        .sort(getComparator(order, orderBy))
                                        .map((app: AppDisplayData) => {
                                            return (
                                                <DataRow
                                                    key={app.mainApp.id}
                                                    row={app}
                                                />
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            )}
        </div>
    );
};
export default DataTable;
