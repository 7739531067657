import React from 'react';
import { Button } from '@airbus/components-react';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next';

import ApplicationChoice from './ApplicationChoice';
import { Application } from '../../../../models/AppModels';
import PlusIcon from '../../../icons/PlusIcon';

import classes from './CardApplications.module.css';

export type ChosenApplication = {
    appId?: number;
    version?: string;
    appVersionId?: number;
};
type Props = {
    applications: Application[];
    commonClasses: {
        readonly [key: string]: string;
    };
    onChosenAppChange: (apps: Record<string, ChosenApplication>) => void;
    chosenApps: Record<string, ChosenApplication>;
};

const CardApplications = ({
    applications,
    commonClasses,
    onChosenAppChange,
    chosenApps,
}: Props) => {
    // ******** HELPERS ********* //
    const copyChosenApps = () => {
        const copy: Record<string, ChosenApplication> = {};
        for (const key in chosenApps) {
            copy[key] = chosenApps[key];
        }
        return copy;
    };

    // ******** FOR DISPLAY ********* //
    const chosenAppIds = Object.entries(chosenApps)
        .map(([, chosenApp]) => chosenApp.appId)
        .filter((id) => id);
    const availableApps = applications
        .filter((app) => !chosenAppIds.includes(app.id))
        .map((app) => ({
            id: app.id,
            name: app.name,
            versions: app.ife_app_versions.map(
                (appVersion) => appVersion.version
            ),
        }));

    const appChoices = Object.entries(chosenApps).map(([uuid]) => {
        const appSelected = applications.find(
            (app) => chosenApps[uuid].appId === app.id
        );
        const appsToSee = [...availableApps];
        if (appSelected) {
            appsToSee.push({
                id: appSelected.id,
                name: appSelected.name,
                versions: appSelected.ife_app_versions.map(
                    (appVersion) => appVersion.version
                ),
            });
        }

        return (
            <ApplicationChoice
                key={uuid}
                applications={appsToSee}
                onSetApp={(appId: number | undefined) => {
                    const copy = copyChosenApps();
                    copy[uuid].appId = appId;
                    onChosenAppChange(copy);
                }}
                onSetVersion={(version: string | undefined) => {
                    const copy = copyChosenApps();
                    copy[uuid].version = version;
                    const appId = copy[uuid].appId;
                    const app = applications.find((a) => a.id === appId);
                    const appVersion = app?.ife_app_versions.find((appV) => {
                        return appV.version === version;
                    });
                    if (appVersion && appVersion.id) {
                        copy[uuid].appVersionId = appVersion.id;
                    }
                    onChosenAppChange(copy);
                }}
                onDelete={() => {
                    const copy = copyChosenApps();
                    delete copy[uuid];
                    onChosenAppChange(copy);
                }}
            />
        );
    });

    return (
        <div className={commonClasses.container_card}>
            <div className={commonClasses.container_header}>
                <p className={commonClasses.container_title}>
                    {i18n.t('deployment.schedule.apps_to_deploy_title')}
                </p>
                <p className={commonClasses.container_subtitle}>
                    {applications.length
                        ? `${applications.length} ${i18n.t(
                              'deployment.schedule.apps_to_deploy_subtitle'
                          )}`
                        : i18n.t('deployment.schedule.app_to_deploy_subtitle')}
                </p>
                <div className={commonClasses.line} />
            </div>
            {applications.length ? (
                <div className={commonClasses.container_body}>
                    <div className={classes.apps_choices}>{appChoices}</div>
                    <Button
                        disabled={
                            Object.keys(chosenApps).length >=
                            applications.length
                        }
                        variant="secondary"
                        icon={<PlusIcon />}
                        onClick={() => {
                            const copy = copyChosenApps();
                            copy[uuidv4()] = {};
                            onChosenAppChange(copy);
                        }}
                    >
                        {i18n.t(
                            'deployment.schedule.apps_to_deploy_add_button'
                        )}
                    </Button>
                </div>
            ) : (
                <p>{i18n.t('deployment.schedule.apps_to_deploy_no_apps')}</p>
            )}
        </div>
    );
};

export default CardApplications;
