import { Company, CompanyDataTable } from '../../models/CompanyModel';

export function companyToDataTable(company: Company): CompanyDataTable {
    return {
        ...company,
        userCount: company.userCount !== 0 ? company.userCount.toString() : '',
        equipedAC: company.equipedAC !== 0 ? company.equipedAC.toString() : '',
    };
}

export function dataTableToCompany(company: CompanyDataTable): Company {
    return {
        ...company,
        userCount: company.userCount !== '' ? parseInt(company.userCount) : 0,
        equipedAC: company.equipedAC !== '' ? parseInt(company.equipedAC) : 0,
    };
}
