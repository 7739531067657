import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import LoadingSpinner from '../loaders/LoginSpinner';
import { Permissions } from '../../utils/permission';

const pathsThatNeedPermission: {
    path: string;
    permission: keyof Permissions;
}[] = [
    { path: 'admin', permission: 'admin' },
    { path: 'validation-center', permission: 'validationCenter' },
    { path: 'deployment', permission: 'deployment' },
    { path: 'aircraft', permission: 'aircraft' },
];
const pathToPermissionPage = (path: string): keyof Permissions | null => {
    for (const permPath of pathsThatNeedPermission) {
        if (path.startsWith('/' + permPath.path)) {
            return permPath.permission;
        }
    }
    return null;
};

type Props = {
    path: string;
    exact: boolean;
    goToLogin: boolean;
    componentIsReloading: boolean;
    isAuthButNoUser: boolean;
    isAuthenticated: boolean;
    permissions: Permissions;
};

const AuthRoute: React.FC<Props> = ({
    path,
    exact,
    goToLogin,
    componentIsReloading,
    isAuthButNoUser,
    isAuthenticated,
    permissions,
    children,
}) => {
    if (path !== '/login' && goToLogin) {
        return <Redirect to="/login" />;
    }

    if (path === '/login' && goToLogin) {
        if (exact) {
            return (
                <Route exact path={path}>
                    {children}
                </Route>
            );
        }
        return <Route path={path}>{children}</Route>;
    }

    if (componentIsReloading || isAuthButNoUser) {
        return <LoadingSpinner />;
    }

    if (isAuthenticated) {
        const location = useLocation();
        const redirectToHome = location.pathname === '/login';
        if (redirectToHome) {
            return <Redirect to="/" />;
        }

        const page = pathToPermissionPage(path);

        if (page !== null && !permissions[page]) {
            return <Redirect to="/" />;
        }

        if (exact) {
            return (
                <Route exact path={path}>
                    {children}
                </Route>
            );
        }
        return <Route path={path}>{children}</Route>;
    }

    return <LoadingSpinner />;
};

export default AuthRoute;
