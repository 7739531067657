import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    DataTable,
    IconButton,
    Spinner,
    Tooltip,
    Typography,
} from '@airbus/components-react';
import i18n from 'i18next';

import classes from './History.module.css';
import { Column, UseSortByColumnOptions } from 'react-table';
import { PostAdd, InfoOutline, Cancel } from '@airbus/icons/react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
    selectToken,
    selectTokenExpiryDate,
} from '../../../redux/app/features/credentialsSlice';
import { apiUrl, isTokenExpired } from '../../../utils/ApplicationUtils';
import { RemoteDeliveryBundle } from '../../../models/DeliveryBundle';
import { useMutation } from 'react-query';
import { AppContext } from '../../../AppContext';
import { prettyDate } from '../../../utils/DateUtils';
import { IError } from '../../../models/AppModels';
import { defaultPageSize } from '../../admin/utils';
import { DataTableQuery } from '../../admin/GenericTable';
import { getDeliveryBundles } from '../../../services/api/Deployment';
import CancelRemoteLoadingModal from './CancelRemoteLoadingModal';
import { beautifyStatus } from '../../../utils/DataTableUtils';
type HistoryDataTable = {
    name: string;
    tailId: string;
    creationDate: string;
    nbApps: string;
    status: string;
    popUpError: {
        errorMessage?: string;
        isSyncOnError: boolean;
    };
    deleteBundle: {
        delete: (() => void) | false;
        isLoading: boolean;
    };
};

const columns: (Column<HistoryDataTable> &
    UseSortByColumnOptions<HistoryDataTable>)[] = [
    {
        Header: `${i18n.t('deployment.history.table.headers.column1')}`,
        accessor: 'name',
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column2')}`,
        accessor: 'tailId',
        // disableSortBy: true,
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column3')}`,
        accessor: 'creationDate',
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column4')}`,
        accessor: 'nbApps',
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column5')}`,
        accessor: 'status',
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column6')}`,
        accessor: 'popUpError',
        Cell: ({
            cell,
        }: {
            cell: {
                value: { errorMessage: string; isSyncOnError: boolean };
            };
        }) => {
            const value = cell.value;
            if (value.isSyncOnError) {
                return (
                    <div className={classes.tooltip}>
                        <Tooltip placement="top" label={value.errorMessage}>
                            <IconButton variant="ghost" aria-label="Cancel">
                                <InfoOutline color="red" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
            return <></>;
        },

        disableSortBy: true,
    },
    {
        Header: `${i18n.t('deployment.history.table.headers.column6')}`,
        accessor: 'deleteBundle',
        Cell: ({
            cell,
        }: {
            cell: { value: { delete: () => void | false; isLoading: boolean } };
        }) => {
            const value = cell.value;
            if (value.delete) {
                if (value.isLoading) {
                    return <Spinner />;
                }

                return (
                    <div className={classes.tooltip}>
                        <Tooltip placement="right" label="Cancel">
                            <IconButton
                                variant="ghost"
                                aria-label="Cancel"
                                onClick={() => {
                                    value.delete();
                                }}
                            >
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
            return <></>;
        },

        disableSortBy: true,
    },
];

const transformAc = (
    bundle: RemoteDeliveryBundle,
    onCancel: () => void,
    cancelIsLoading: boolean
): HistoryDataTable => {
    const canDelete = ![
        'SYNC_STARTED',
        'SYNC_SUCCESS',
        'SYNC_ERROR',
        'CANCELLED',
    ].includes(bundle.status);
    const deleteMethode = canDelete ? onCancel : false;

    return {
        creationDate: prettyDate(bundle.creationDate),
        name: bundle.name,
        nbApps: bundle.nbApps.toString(),
        status: beautifyStatus(bundle.status),
        popUpError:
            bundle.status === 'SYNC_ERROR'
                ? { errorMessage: bundle.userMessage, isSyncOnError: true }
                : { isSyncOnError: false },
        tailId: bundle.tailId,
        deleteBundle: {
            delete: deleteMethode,
            isLoading: cancelIsLoading,
        },
    };
};

const cancelBundleRequest = async ({
    bundleId,
    bundleName,
    token,
    tokenExpiryDate,
    setMessageBanner,
    onSuccess,
    onError,
}: {
    bundleId: number;
    bundleName: string;
    token?: string;
    tokenExpiryDate?: number;
    setMessageBanner: (err: IError) => void;
    onSuccess: () => void;
    onError: () => void;
}) => {
    const options = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };

    if (isTokenExpired(tokenExpiryDate ?? 0)) {
        return;
    }

    const url = `${apiUrl}/cancel-onboard-deployment`;

    try {
        const data = await axios.post(url, { targetId: bundleId }, options);
        if (data.data.error) {
            setMessageBanner({
                isBanner: true,
                message: data.data.error.message,
                type: 'error',
            });
        } else {
            setMessageBanner({
                isBanner: true,
                message: i18n
                    .t('deployment.history.table.onCancelDeploymentSuccess')
                    .replace('###$$$', bundleName),
                type: 'success',
            });
            onSuccess();
        }
    } catch (err: any) {
        setMessageBanner({
            isBanner: true,
            message: err.message,
            type: 'error',
        });
        onError();
    }
};
const History = () => {
    const { setMessageBanner } = useContext(AppContext);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const [query, setQuery] = useState<DataTableQuery>({
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy: [],
    });
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);

    const [targetToCancel, setTargetToCancel] = useState<
        | {
              id: number;
              name: string;
          }
        | undefined
    >(undefined);
    const [isCancelDeploymentModalOpen, setIsCancelDeploymentModalOpen] =
        useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(refetchBundle, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const {
        data: bundleData,
        isLoading: bundleIsLoading,
        refetch: refetchBundle,
    } = getDeliveryBundles(
        query,
        token,
        tokenExpiryDate,
        pageCount,
        setPageCount,
        rowCount,
        setRowCount
    );

    const { mutate: cancelBundle, isLoading: cancelIsLoading } =
        useMutation(cancelBundleRequest);

    const onQueryUpdate = useCallback((queryParams: DataTableQuery) => {
        if (queryParams.sortBy.length > 1) {
            queryParams.sortBy.splice(0, queryParams.sortBy.length - 1);
        }
        setQuery(queryParams);
    }, []);

    return (
        <div className={classes.page}>
            <div className={classes.page_container}>
                <div className={classes.generic_table}>
                    {bundleData === undefined ||
                    bundleData === null ||
                    bundleData.length ? (
                        <DataTable
                            columns={columns}
                            data={(bundleData ?? [])
                                .filter((b) => b.tailId !== 'VALCENTER')
                                .flatMap((b) => {
                                    return transformAc(
                                        b,
                                        () => {
                                            setTargetToCancel({
                                                id: b.id,
                                                name: b.name,
                                            });
                                            setIsCancelDeploymentModalOpen(
                                                true
                                            );
                                        },
                                        cancelIsLoading &&
                                            b.id === targetToCancel?.id
                                    );
                                })}
                            loading={bundleIsLoading}
                            onQueryUpdate={onQueryUpdate}
                            pageCount={pageCount}
                            rowCount={rowCount}
                            sortBy={query.sortBy}
                        />
                    ) : (
                        <div className={classes.no_apps}>
                            <PostAdd />
                            <Typography
                                variant="large"
                                className={classes.noapps_title}
                            >
                                {i18n.t(
                                    'deployment.history.table.noBundles.title'
                                )}
                            </Typography>
                            <Typography
                                variant="small"
                                className={classes.noapps_content}
                            >
                                {i18n.t(
                                    'deployment.history.table.noBundles.content'
                                )}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
            <CancelRemoteLoadingModal
                isOpen={isCancelDeploymentModalOpen}
                deploymentName={targetToCancel ? targetToCancel.name : ''}
                onClose={() => setIsCancelDeploymentModalOpen(false)}
                onProceed={() => {
                    setIsCancelDeploymentModalOpen(false);

                    if (!targetToCancel) {
                        return;
                    }

                    if (!bundleData?.length) {
                        return;
                    }

                    cancelBundle({
                        bundleId: targetToCancel.id,
                        bundleName: targetToCancel.name,
                        token,
                        tokenExpiryDate,
                        setMessageBanner,
                        onSuccess: () => {
                            refetchBundle();
                            setTargetToCancel(undefined);
                        },
                        onError: () => {
                            setTargetToCancel(undefined);
                        },
                    });
                }}
            />
        </div>
    );
};

export default History;
