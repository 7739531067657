import React from 'react';
import Role from '../../../models/RoleModel';
import { useCustomQuery } from '../utils';
import { RoleTable } from './RoleTable';
import './userRoleTable.css';

type Props = {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
    userWithRoles: number[] | undefined;
    onChangeRoles: (rolesIds: number[]) => void;
    setValidationDisable: (bool: boolean) => void;
};
export const UserRoleTable = ({
    token,
    tokenExpiryDate,
    userWithRoles,
    onChangeRoles,
    setValidationDisable,
}: Props) => {
    const { data: roles } = useCustomQuery<Role[], any>(
        token,
        tokenExpiryDate,
        'role',
        true,
        false,
        'label',
        { isStatic: 'false' }
    );

    return (
        <div className="role-permission-container">
            <span className="permissions-title">Roles</span>
            <hr className="role-permission-divider"></hr>
            <div className="checkbox-group">
                {roles?.length && (
                    <RoleTable
                        selectedRoles={userWithRoles}
                        options={roles}
                        onChangeRoles={onChangeRoles}
                        setValidationDisable={setValidationDisable}
                    />
                )}
            </div>
        </div>
    );
};
