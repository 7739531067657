import { CheckboxGroup, Checkbox } from '@airbus/components-react';
import React from 'react';

type Props = {
    options: {
        label: string;
        value: string;
    }[];
    currentChosenOptions: string[];
    onFilterChange: (selectedAirlines: number[]) => void;
};

const AirlineFilter = ({
    options,
    currentChosenOptions,
    onFilterChange,
}: Props) => {
    return (
        <>
            <CheckboxGroup
                name="aircrafts-filter-by-aircrafts"
                value={currentChosenOptions}
                onChange={(e, value) =>
                    onFilterChange(value.map((v: string) => Number(v)))
                }
            >
                {options.map((option, index) => (
                    <Checkbox
                        key={index}
                        label={option.label}
                        value={option.value}
                    />
                ))}
            </CheckboxGroup>
        </>
    );
};

export default AirlineFilter;
